import * as React from "react";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import useDialog, {DialogElementType} from "../../../layout/popUps/dialog/useDialog";
import {OrderDetails} from "../../../../../utils/types/structures/orderDetails";
import {PopoverClose} from "@radix-ui/react-popover";
import AssignDeliveryDate from "../../../layout/popUps/sheet/content/orderDetails/assign-delivery-date";
import useSheet from "../../../layout/popUps/sheet/useSheet";
import {OrderFulfillmentStatus} from "../../../../../utils/enums/orderFulfillmentStatus";

type Props = {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function MoreActionsHandler({order, setOrder}: Props) {
    const {open} = useDialog()
    const {open: openSheet} = useSheet()

    return <Popover>
        <PopoverTrigger asChild>
            <button data-testid={'on-show-actions'}
                    className={'text-[16px] leading-[20px] border-0 md:border md:border-1 md:border-input hover:bg-accent hover:text-accent-foreground font-inter disabled:opacity-50 rounded-full bg-[#F7F7F7] md:hover:bg-accent md:bg-background md:rounded-md h-[40px] w-[40px] flex items-center justify-center whitespace-nowrap'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.0003 10.8346C10.4606 10.8346 10.8337 10.4615 10.8337 10.0013C10.8337 9.54106 10.4606 9.16797 10.0003 9.16797C9.54009 9.16797 9.16699 9.54106 9.16699 10.0013C9.16699 10.4615 9.54009 10.8346 10.0003 10.8346Z"
                        fill="#222222" stroke="#222222" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path
                        d="M10.0003 4.9987C10.4606 4.9987 10.8337 4.6256 10.8337 4.16536C10.8337 3.70513 10.4606 3.33203 10.0003 3.33203C9.54009 3.33203 9.16699 3.70513 9.16699 4.16536C9.16699 4.6256 9.54009 4.9987 10.0003 4.9987Z"
                        fill="#222222" stroke="#222222" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path
                        d="M10.0003 16.6667C10.4606 16.6667 10.8337 16.2936 10.8337 15.8333C10.8337 15.3731 10.4606 15 10.0003 15C9.54009 15 9.16699 15.3731 9.16699 15.8333C9.16699 16.2936 9.54009 16.6667 10.0003 16.6667Z"
                        fill="#222222" stroke="#222222" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
            </button>
        </PopoverTrigger>
        <PopoverContent align={'end'} className="w-fit h-fit p-[5px]">
            <PopoverClose className={'p-0'}>
                <div
                    className={'p-2 rounded-[6px] w-full h-full bg-white hover:bg-[#F5F5F5] text-[#222222] text-[14px] leading-[20px] font-medium'}
                    onClick={() => {
                        open(DialogElementType.contactSupport, order, 'Partner Support Assistance')
                    }}>Partner support
                </div>

                {!order.deliverySpecificDate && (order.fulfillmentStatus !== OrderFulfillmentStatus.surveyAtDelivery
                        && order.fulfillmentStatus !== OrderFulfillmentStatus.awaitingSignatureAtDelivery
                        && OrderFulfillmentStatus.awaitingPaymentAtDelivery
                        && OrderFulfillmentStatus.deliveryStarted
                        && OrderFulfillmentStatus.awaitingSignatureForConfirmation
                        && OrderFulfillmentStatus.delivered
                        && OrderFulfillmentStatus.cancelled
                        && OrderFulfillmentStatus.forfeited
                        && OrderFulfillmentStatus.paymentDispute
                        && OrderFulfillmentStatus.onHold
                    ) &&
                    <div
                        className={'p-2 rounded-[6px] w-full h-full bg-white hover:bg-[#F5F5F5] text-[#222222] text-[14px] leading-[20px] font-medium'}
                        onClick={() => {
                            if (!order.deliverySpecificDate) openSheet('Schedule', <AssignDeliveryDate
                                order={order} setOrder={setOrder}/>)
                        }}>Update schedule</div>}
            </PopoverClose>
        </PopoverContent>
    </Popover>
}