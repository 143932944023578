import React, {useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Checkbox} from "src/components/checkBox";
import {Command} from "src/components/command";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {ScrollArea} from "src/components/scrollArea";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import {MinusIcon} from "src/media/icons/custom/minusIcon";
import {PlusIcon} from "src/media/icons/custom/plusIcon";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {deleteOrderCustomItems} from "src/utils/apiCalls/orderDetails/customItems/deleteOrderCustomItems";
import {getOrderCustomItems} from "src/utils/apiCalls/orderDetails/customItems/getOrderCustomItems";
import {updateOrderCustomItems} from "src/utils/apiCalls/orderDetails/customItems/updateOrderCustomItems";
import {deleteOrderItems} from "src/utils/apiCalls/orderDetails/orderItems/deleteOrderItems";
import {getOrderItems} from "src/utils/apiCalls/orderDetails/orderItems/getOrderItems";
import {updateOrderItems} from "src/utils/apiCalls/orderDetails/orderItems/updateOrderItems";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import getSimpleString from "src/utils/stringHandlers/getSimpleString";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {CustomItem} from "src/utils/types/structures/customItem";
import {Item} from "src/utils/types/structures/item";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderItem} from "src/utils/types/structures/orderItem";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import getExtendedItemVolumeAndWeight
    from "src/utils/zustandStores/orderDetailsInventory/utils/getExtendedItemVolumeAndWeight";
import {getOrder} from "../../../../../../../../../utils/apiCalls/orderDetails/getOrder";

interface Props {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

enum ButtonStatus {
    save = 'SAVE',
    deleteItems = 'DELETE_ITEMS'
}

export default function SearchInventory({order, setOrder}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<Item[]>([]);
    const isOutside = useOnclickOutside(() => {
        setSearchResults([])
    });
    const [flag, setFlag] = useState<boolean>(false)
    const [itemToAdd, setItemToAdd] = useState<Item | null>(null);
    const navigate = useNavigate();
    const [SaveOrDeleteButtonCurrentStatus, setSaveOrDeleteButtonCurrentStatus] = useState<ButtonStatus>(ButtonStatus.save);


    async function initiateOrder() {
        const res = await getOrder(order.id)
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate(routes.login))
        } else {
            setOrder(res)
        }
    }

    const {
        items,
        currentExtendedItems,
        onExtendedItemCheckChange,
        onCheckAllChange,
        isAnySelected,
        onExtendedItemChangeCount,
        onExtendedItemAdd,
        onExtendedItemDelete,
        getSortedItemsToRequestLoad,
        initExtendedItems
    } = useOrderInventory()

    const {close} = useSheet();

    useEffect(() => {
        const item = items.filter(el => el.name.toLowerCase() === searchInputValue.toLowerCase())[0]
        if (item) setItemToAdd(item)

        if (flag) {
            const results = items.filter(el => el.name.toLowerCase().includes(searchInputValue.toLowerCase()))
            setSearchResults(results)
        }
    }, [searchInputValue]);

    useEffect(() => {
        if (isAnySelected) {
            setSaveOrDeleteButtonCurrentStatus(ButtonStatus.deleteItems)
        } else setSaveOrDeleteButtonCurrentStatus(ButtonStatus.save)
    }, [isAnySelected]);

    function getButtonName() {
        if (isLoading) {
            return <LoadingStatus/>
        } else {
            if (SaveOrDeleteButtonCurrentStatus === ButtonStatus.save) {
                return 'Save'
            }

            if (SaveOrDeleteButtonCurrentStatus === ButtonStatus.deleteItems) {
                return 'Delete selected'
            }
        }
    }

    async function SaveDeleteButtonOnClickHandler() {

        if (SaveOrDeleteButtonCurrentStatus === ButtonStatus.deleteItems) {
            onExtendedItemDelete()
        } else {
            setLoading(true)
            const sortedItems = await getSortedItemsToRequestLoad();

            const itemsIdsToDelete = sortedItems.orderItemsToDelete.filter(el => el.id !== null).map(el => el.id)
            if (itemsIdsToDelete.length > 0) {
                const deleteOrderItemsRes = await deleteOrderItems(order.id, itemsIdsToDelete)

                if (deleteOrderItemsRes && isInstanceOf<ErrorMessage>(deleteOrderItemsRes, 'message')) {
                    handleResponseError(deleteOrderItemsRes, () => navigate(routes.login))
                }
            }

            if (sortedItems.currentOrderItems.length > 0) {
                const updatedOrderItems = await updateOrderItems(order.id, sortedItems.currentOrderItems)
                if (isInstanceOf<ErrorMessage>(updatedOrderItems, 'message')) {
                    handleResponseError(updatedOrderItems, () => navigate('/'))
                }
            }

            const customItemsToDelete = sortedItems.customItemsToDelete.map(el => el.id)
            if (customItemsToDelete.length > 0) {
                const res = await deleteOrderCustomItems(order.id, customItemsToDelete)
                if (res && isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate('/'))
                }
            }

            if (sortedItems.currentCustomItems.length > 0) {
                const updatedCustomItems = await updateOrderCustomItems(order.id, sortedItems.currentCustomItems)
                if (isInstanceOf<ErrorMessage>(updatedCustomItems, 'message')) {
                    handleResponseError(updatedCustomItems, () => navigate('/'))
                }
            }

            let currentOrderItems: OrderItem[] = []
            let currentCustomItems: CustomItem[] = []

            const orderItems = await getOrderItems(order.id);
            if (isInstanceOf<ErrorMessage>(orderItems, 'message')) {
                handleResponseError(orderItems, () => navigate('/'))
            } else {
                currentOrderItems = orderItems
            }

            const customItems = await getOrderCustomItems(order.id);
            if (isInstanceOf<ErrorMessage>(customItems, 'message')) {
                handleResponseError(customItems, () => navigate('/'))
            } else {
                currentCustomItems = customItems
            }

            initExtendedItems(currentOrderItems, currentCustomItems)
            await initiateOrder()
            setLoading(false)
            close()
        }
    }

    return <section className={'h-[calc(100dvh-8.7rem)] flex flex-col pt-[22px]'}>
        <section className={'grow flex flex-col gap-6 px-6'}>
            <div className={'flex flex-col gap-2'}>
                <h3 className={'text-[#222222] text-[14px] leading-[16px] font-medium'}>Search by name</h3>

                <div key={'add-item-input'} className={'flex gap-4'}>

                    <div className={'relative flex-1'}>
                        <Command className={'border rounded-md'}>
                            <div className="flex items-center gap-0 pl-0 pr-3">
                                <Input className={'text-base text-foreground border-none pr-1'} type={'text'}
                                       value={searchInputValue}
                                       onInput={(e) => {
                                           setFlag(true)
                                           setSearchInputValue(e.currentTarget.value)
                                       }} placeholder={'Search by name'}/>
                            </div>
                        </Command>

                        {(searchResults.length <= 5 && searchResults.length > 0) &&
                            <ul ref={isOutside}
                                className={'absolute w-full bg-background z-30 border rounded-md mt-2 p-[5px]'}>
                                {items.filter(el => el.name.toLowerCase()
                                    .includes(searchInputValue.toLowerCase()))
                                    .map(el =>
                                        <li
                                            key={el.name}
                                            onClick={() => {
                                                onExtendedItemAdd(el);
                                                setSearchInputValue('');
                                                setSearchResults([]);
                                                setFlag(false)
                                            }}
                                            className={'hover:bg-marcoOnHover rounded-md  p-3 cursor-pointer group flex flex-row justify-between items-center'}>
                                            <span className={'text-sm text-foreground'}>{el.name}</span>
                                            <span
                                                className={'text-xs font-medium leading-none invisible group-hover:visible'}>Add item</span>
                                        </li>)}
                            </ul>
                        }

                        {(searchResults.length > 5) &&
                            <ul ref={isOutside}
                                className={'absolute w-full bg-background z-30 border rounded-md mt-2 p-[5px]'}>
                                <ScrollArea className={'h-[13.75rem]'}>
                                    {items.filter(el => el.name.toLowerCase().includes(searchInputValue.toLowerCase())).map(el =>
                                        <li
                                            key={el.name}
                                            onClick={() => {
                                                onExtendedItemAdd(el);
                                                setSearchInputValue('');
                                                setSearchResults([]);
                                                setFlag(false)
                                            }}
                                            className={'hover:bg-marcoOnHover rounded-md  p-3 cursor-pointer group flex flex-row justify-between items-center'}>
                                            <span className={'text-sm text-foreground'}>{el.name}</span>
                                            <span
                                                className={'text-xs font-medium leading-none invisible group-hover:visible'}>Add item</span>

                                        </li>)}
                                </ScrollArea>
                            </ul>}
                    </div>
                </div>
            </div>

            <div>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className={'grid items-center place-content-center'}><Checkbox
                                className={'grid items-center'} checked={isAnySelected}
                                onCheckedChange={onCheckAllChange}/></TableHead>
                            <TableHead className={'w-[150px]'}>Item name</TableHead>
                            <TableHead>Volume</TableHead>
                            <TableHead className={'text-center w-[125px]'}>Qty</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {currentExtendedItems.map(el => <TableRow key={el.name}>
                            <TableCell className={'grid items-center place-content-center'}><Checkbox
                                className={'grid items-center'} checked={el.isSelected}
                                onCheckedChange={() => onExtendedItemCheckChange(el)}/></TableCell>
                            <TableCell>{el.name}</TableCell>
                            <TableCell>{getExtendedItemVolumeAndWeight(el.item, items).volume} cu.ft</TableCell>
                            <TableCell>
                                <div className={'w-[104px] flex flex-row justify-between items-center'}>
                                    <Button
                                        className={'w-[32px] h-[32px] p-0 rounded-full border border-[#717171]'}
                                        variant={'ghost'}
                                        onClick={() => {
                                            if (el.item.count > 1) {
                                                onExtendedItemChangeCount(el, el.item.count - 1)
                                            }
                                        }}
                                    >
                                        <MinusIcon stroke={'#717171'} size={16}/>
                                    </Button>

                                    <div
                                        className={'text-center text-[#222222] text-[16px] leading-[24px] font-normal'}
                                    >{getSimpleString(el.item.count)}</div>

                                    <Button
                                        className={'w-[32px] h-[32px] p-0 rounded-full border border-[#717171]'}
                                        variant={'ghost'}
                                        onClick={() => onExtendedItemChangeCount(el, el.item.count + 1)}>
                                        <PlusIcon size={16}/>
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </div>
        </section>

        <section
            className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4'}>
            <Button size={'lg'}
                    variant={'outline'}
                    className={'w-full text-base font-medium leading-tight'}
                    disabled={isLoading}
                    onClick={() => {
                        close()
                    }}>Cancel</Button>

            <Button size={'lg'}
                    variant={SaveOrDeleteButtonCurrentStatus === ButtonStatus.save ? 'default' : 'red'}
                    onClick={SaveDeleteButtonOnClickHandler}
                    disabled={isLoading}
                    className={'w-full text-base font-medium leading-tight'}>{getButtonName()}</Button>
        </section>
    </section>
}