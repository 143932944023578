import dayjs from "dayjs";

export const dateTimeRangeToString = (date: string | Date | null | undefined, time: 'MORNING' | 'AFTERNOON' | 'EVENING' | null | undefined, isSpecific: boolean) => {
    if (date) {
        if (!isSpecific) {

            let dateString: string = ``

            const previousDate = dayjs(date).subtract(1, 'day')
            const nextDate = dayjs(date).add(1, 'day')

            if (previousDate.month() === nextDate.month()) {
                dateString = previousDate.format('MMM DD') + '-' + nextDate.format('DD')
            } else dateString = previousDate.format('MMM DD') + '-' + nextDate.format('MMM DD')

            if (time) {
                switch (time) {
                    case 'MORNING': {
                        return dateString + ', ' + '8-11 AM'
                    }

                    case 'AFTERNOON': {
                        return dateString + ', ' + '12-3 PM'
                    }

                    case 'EVENING': {
                        return dateString + ', ' + '4-6 PM'
                    }

                    default: {
                        return dateString;
                    }
                }

            } else return dateString

        } else {
            const dateString = `${dayjs(date).format('ddd, MMM DD')}`;
            if (time) {
                switch (time) {
                    case 'MORNING': {
                        return dateString + ', ' + '8-11 AM'
                    }

                    case 'AFTERNOON': {
                        return dateString + ', ' + '12-3 PM'
                    }

                    case 'EVENING': {
                        return dateString + ', ' + '4-6 PM'
                    }

                    default: {
                        return dateString;
                    }
                }

            } else return dateString
        }
    } else return 'TBD'
}