import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import CarrierName from "src/pages/dashboarSection/myLoads/components/carrierName";
import fetcher from "src/utils/apiCalls/fetcher";
import {ApiHost} from "src/utils/defaults/apiHost";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {Member} from "src/utils/types/structures/member";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useSWR from "swr";
import DriverAssign from "../../../../layout/popUps/sheet/content/orderDetails/driverAssign/driverAssign";

interface Props {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function DriverInfoSection({order, setOrder}: Props) {
    const {user} = useTokenStore()
    const {error} = useSWR<Member[]>((user && user.roles !== 'DRIVER') ? ApiHost + encodeURI(`api/v1/company/user`) : null, fetcher);
    const {open} = useSheet()
    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate(routes.login))
        }
    }, [error]);

    return <section key={'driver-info'} className={'flex flex-col gap-4 border-b py-6'}>
        <div className={'flex justify-between items-center gap-4'}>
            <h2 className={'text-[#222222] text-[18px] leading-[24px] font-semibold'}>Crew</h2>
            {user && user.roles !== 'DRIVER' ?
                order.driver
                    ? <Button
                        data-testid={'manage-fleet'}
                        onClick={() => open('Manage', <DriverAssign setOrder={setOrder} order={order}/>)}
                        variant={'outline'}
                        className={'text-sm !text-[#222222] font-medium'}>Manage</Button>
                    : <Button
                        data-testid={'assign-driver'}
                        onClick={() => open('Assign', <DriverAssign setOrder={setOrder} order={order}/>)}
                        variant={'outline'}
                        className={'text-sm text-foreground font-medium flex gap-2'}>
                        Assign
                    </Button>

                : null
            }
        </div>

        <CarrierName order={order}/>
    </section>
}