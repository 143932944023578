import {useEffect, useState} from "react";
import {Button} from "src/components/button";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import {DownLoadIcon} from "src/media/icons/custom/downloadIcon";
import {TrashBinIcon} from "src/media/icons/custom/trashBinIcon";
import {BlobType} from "src/utils/enums/blobType";
import {OrderImageOwner} from "src/utils/enums/orderImageOwner";
import getExtensionType from "src/utils/stringHandlers/images/getExtensionType";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import {ShevronLeft} from "../../../../../media/icons/custom/shevron/shevronLeft";
import {Colors} from "../../../../../utils/defaults/colors";
import {ShevronRight} from "../../../../../media/icons/custom/shevron/shevronRight";
import {OrderFulfillmentStatus} from "../../../../../utils/enums/orderFulfillmentStatus";

const minSwipeDistance = 50

export default function MobileContent() {
    const {
        currentOrderId,
        deleteCurrent,
        downloadImage,
        setNextImage,
        setPreviousImage,
        isNext,
        isPrevious,
        totalImages,
        currentPosition,
        closeImageViewer,
        currentImage,
        order
    } = useOrderAttachments()
    const [blobType, setBlobType] = useState<BlobType | null>(null)
    const [touchStart, setTouchStart] = useState<number | null>(null)
    const [touchEnd, setTouchEnd] = useState<number | null>(null)

    useEffect(() => {
        setBlobType(null)
        if (currentImage) {
            const extension = getExtensionType(currentImage)
            setBlobType(extension)
        }
    }, [currentImage]);

    const onTouchStart = (e: React.TouchEvent<HTMLImageElement> | React.TouchEvent<HTMLVideoElement> | React.TouchEvent<HTMLDivElement>) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e: React.TouchEvent<HTMLImageElement> | React.TouchEvent<HTMLVideoElement> | React.TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe && isNext) setNextImage()
        if (isRightSwipe && isPrevious) setPreviousImage()
    }

    if (currentImage) {
        return <div className={'flex flex-col h-full w-full'}>
            <div
                className={'flex-none h-17 border-b border-[#222222] flex md:hidden justify-between place-items-center px-4 pt-2'}>

                <div className={'flex flex-col gap-0'}>
                    <span
                        className={'text-[#DEDEDE] text-[16px] leading-[20px] font-semibold'}>{order?.sid ? order.sid : currentOrderId}</span>
                    <span
                        className={'text-[#9F9F9F] text-[14px] leading-[20px] font-medium'}>Media {currentPosition} of {totalImages}</span>
                </div>

                <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'}
                        onClick={closeImageViewer}><CrossIcon size={24} className={'fill-marcoWhite'}/></Button>
            </div>

            <div className={'flex-1 flex flex-row md:hidden items-center gap-2'}>

                <div onClick={setPreviousImage}
                     className={`hidden md:block ml-[42px] cursor-pointer rounded-full p-2 h-fit w-fit hover:bg-white hover:bg-opacity-10 flex-none ${!isPrevious && 'invisible'}`}>
                    <ShevronLeft size={24} stroke={Colors.white}/>
                </div>

                {(blobType && blobType === BlobType.image) && <img
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                    onTouchMove={onTouchMove}
                    className={`border-none rounded-xl h-full w-full object-scale-down px-4`}
                    src={currentImage.image.url}
                    alt={'img'}/>}

                {(blobType && blobType === BlobType.video) && <video
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                    onTouchMove={onTouchMove}
                    controls
                    className={`border-none rounded-xl h-full w-full object-scale-down px-4`}
                    src={currentImage.image.url}
                />}

                {(blobType && blobType === BlobType.pdf) &&
                    <iframe onTouchStart={onTouchStart}
                            onTouchEnd={onTouchEnd}
                            onTouchMove={onTouchMove}
                            className={'border-none rounded-xl h-full w-full object-scale-down px-4'}
                            src={`https://docs.google.com/viewer?url=${currentImage.image.url}&embedded=true`}/>
                }
                <div onClick={setNextImage}
                     className={`mr-[42px] hidden sm:block h-fit w-fit cursor-pointer rounded-full p-2 hover:bg-white hover:bg-opacity-10 flex-none ${!isNext && 'invisible'}`}>
                    <ShevronRight size={24} stroke={Colors.white}/>
                </div>
            </div>

            <div className={'flex-none h-13 flex md:hidden justify-between place-items-center px-4 pb-4'}>
                {currentImage.owner === OrderImageOwner.carrier &&
                    <Button variant={'icon'} size={"iconFit"}
                            className={`${order?.fulfillmentStatus && order.fulfillmentStatus === OrderFulfillmentStatus.delivered && 'hidden'} hover:bg-marcoDarkHover p-1`}
                            onClick={deleteCurrent}><TrashBinIcon size={24} className={'fill-marcoWhite'}/></Button>}
                <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'}
                        onClick={downloadImage}><DownLoadIcon size={24} className={'fill-marcoWhite'}/></Button>
            </div>
        </div>
    } else return <></>
}