import React, {useEffect, useState} from "react";
import {Button} from "src/components/button";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import CompleteUnboarding from "src/pages/dashboarSection/layout/popUps/dialog/content/completeUnboarding";
import ContactDetails from "src/pages/dashboarSection/layout/popUps/dialog/content/contactDetails";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {Member} from "src/utils/types/structures/member";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import PINform from "./content/PINform";
import ShowAvatar from "./content/showAvatar";
import ContactSupport from "./content/contactSupport";

export default function DialogWrapper() {
    const [dialogContent, setDialogContent] = useState<React.ReactNode | null>(null);
    const {isOpen, value, type, close, title} = useDialog();
    const {width} = useViewport()

    useEffect(() => {
        switch (type) {
            case DialogElementType.personalSettingsShowAvatar: {
                if (value === null) {
                    close()
                } else {
                    if (isInstanceOf<Member>(value, 'id')) {
                        setDialogContent(<ShowAvatar user={value}/>)
                    }
                }
                break
            }

            case DialogElementType.PINForm: {
                if (value === null) {
                    close()
                } else {
                    if (isInstanceOf<OrderDetails>(value, 'id')) {
                        setDialogContent(<PINform order={value}/>)
                    }
                }
                break
            }

            case DialogElementType.completeUnboarding: {
                if (value === null) close()
                else setDialogContent(<CompleteUnboarding/>)
                break
            }

            case DialogElementType.contactDetails: {
                if (value === null) {
                    close()
                } else {
                    if (isInstanceOf<OrderDetails>(value, 'id')) {
                        setDialogContent(<ContactDetails order={value}/>)
                    }
                }
                break
            }

            case DialogElementType.contactSupport: {
                if (value === null) {
                    close()
                } else {
                    if (isInstanceOf<OrderDetails>(value, 'id')) {
                        setDialogContent(<ContactSupport order={value}/>)
                    }
                }
                break
            }
        }
    }, [value, type]);

    useEffect(() => {
        isOpen && (document.body.style.overflow = 'hidden');
        !isOpen && (document.body.style.overflow = 'unset');
    }, [isOpen]);

    return <div
        onScroll={event => {
            event.preventDefault()
            event.stopPropagation()
        }}
        className={`${isOpen ? 'fixed' : 'hidden'} h-[100dvh] w-full bg-marcoOverlay z-50 font-inter text-sm grid place-items-center`}>

        <div
            className={`${width <= defaultDimensions.sm && 'fixed bottom-0'} w-full h-fit sm:rounded-xl sm:max-w-[536px] rounded-t-xl bg-white shadow-lg flex flex-col`}>

            <section className={'flex-none'}>
                <div className={'flex justify-between pt-6 pb-4 px-6 place-items-center'}>
                    <h1 className={'text-[#222222] text-[22px] leading-[26px] font-semibold'}>{title}</h1>
                    <Button variant={'ghost'} size={'iconFit'}
                            className={`p-1 ${type === DialogElementType.completeUnboarding ? 'invisible' : ''}`}
                            onClick={() => close()}><CrossIcon size={24} className={'fill-foreground'}/></Button>
                </div>
            </section>

            <section className={'flex-1 relative'}>{dialogContent}</section>
        </div>
    </div>
}