import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Tabs, TabsContent, TabsList} from "src/components/tabs";
import {toast} from "src/components/useToast";
import CrewContent
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/driverAssign/driverAssignTabsContent/crewContent";
import DriverAssignTabTrigger
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/driverAssign/driverAssignTabTrigger";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {assignDriver} from "src/utils/apiCalls/assignDriver";
import fetcher from "src/utils/apiCalls/fetcher";
import {unassignDriver} from "src/utils/apiCalls/unassignDriver";
import {ApiHost} from "src/utils/defaults/apiHost";
import routes from "src/utils/defaults/routes";
import {OrderFulfillmentStatus} from "src/utils/enums/orderFulfillmentStatus";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {Member} from "src/utils/types/structures/member";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useSWR from "swr";
import {SpinnerIcon} from "../../../../../../../../media/icons/custom/spinnerIcon";
import {getOrder} from "../../../../../../../../utils/apiCalls/orderDetails/getOrder";

interface Props {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export enum AssignDriverSections {
    crew = 'CREW'
}

export default function DriverAssign({order, setOrder}: Props) {
    const navigate = useNavigate();
    const {close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);
    const {data: companyMemberData, error} = useSWR<Member[]>(ApiHost + encodeURI(`api/v1/company/user`), fetcher);
    const [currentActiveTab, setCurrentActiveTab] = useState<AssignDriverSections>(AssignDriverSections.crew);
    const [currentDriverId, setCurrentDriverId] = useState<string | null | undefined>(null)

    async function updateOrder() {
        getOrder(order.id)
            .then(res => {
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.login))
                } else {
                    setOrder(res)
                }
            })

    }

    useEffect(() => {
        setCurrentDriverId(order.driver?.id)
    }, [order.driver]);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate(routes.login))
        }
    }, [error]);

    async function onSave() {
        setLoading(true)
        if (order) {
            if (currentDriverId) {
                const res = await assignDriver(order.id, currentDriverId);
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.login))
                } else {
                    await updateOrder()
                    close()
                }
            } else {
                if (order.driver) {
                    if (order.fulfillmentStatus !== OrderFulfillmentStatus.accepted) {
                        toast({variant: 'destructive', description: 'Driver not assigned'})
                    } else {
                        const res = await unassignDriver(order.id, order.driver.id)
                        if (isInstanceOf<ErrorMessage>(res, 'message')) {
                            handleResponseError(res, () => navigate(routes.main))
                        } else {
                            await updateOrder()
                            close()
                        }
                    }
                }
            }
        }

        setLoading(false)
    }

    return <section className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>
            <Tabs value={currentActiveTab} className="grow w-full py-4 px-6">
                <TabsList
                    className={'flex gap-2 text-base font-medium rounded-none bg-opacity-0 justify-start px-0 pt-[1rem] pb-[1.125rem]'}>
                    <DriverAssignTabTrigger value={AssignDriverSections.crew} currentActive={currentActiveTab}
                             setCurrentActive={setCurrentActiveTab} title={'Crew'}/>
                </TabsList>

                <TabsContent value={AssignDriverSections.crew}><CrewContent currentDriverId={currentDriverId} setCurrentDriverId={setCurrentDriverId} companyMemberData={companyMemberData}/></TabsContent>
            </Tabs>

        <section
            className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4'}>
            <Button size={'lg'}
                    variant={'outline'}
                    data-testid={'on-cancel-handle'}
                    className={'w-full text-base font-medium leading-tight'}
                    onClick={close}>Cancel</Button>
            <Button
                onClick={onSave}
                size={'lg'}
                variant={'default'}
                disabled={isLoading}
                data-testid={'on-submit-handle'}
                className={'w-full text-base font-medium leading-tight relative'}>
                <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
            </Button>
        </section>
    </section>
}