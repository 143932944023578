import {ExtendedOrderImage} from "../utils/zustandStores/orderDetailsAttachments/orderAttachments";
import {useState} from "react";
import {ImageMetadata} from "../utils/types/structures/imageMetadata";
import {OrderFulfillmentStatus} from "../utils/enums/orderFulfillmentStatus";

type Props = {
    orderImage: ExtendedOrderImage
}

export const ImageBadge = ({orderImage}: Props) => {
    const getName = () => {
        if (typeof orderImage.image.metadata === 'string') {
            const metadata = JSON.parse(orderImage.image.metadata) as ImageMetadata;
            switch (metadata.orderStatusAtUpload) {
                case OrderFulfillmentStatus.accepted:
                case OrderFulfillmentStatus.notAccepted:
                case OrderFulfillmentStatus.dispatched:
                case OrderFulfillmentStatus.arrivedAtPickup:
                case OrderFulfillmentStatus.surveyAtPickup:
                case OrderFulfillmentStatus.awaitingSignatureAtPickup:
                case OrderFulfillmentStatus.awaitingPaymentAtPickup:
                case OrderFulfillmentStatus.pickupStarted: {
                    return 'Pickup'
                }

                case OrderFulfillmentStatus.inTransit: {
                    return 'In transit'
                }

                case OrderFulfillmentStatus.arrivedAtDelivery:
                case OrderFulfillmentStatus.surveyAtDelivery:
                case OrderFulfillmentStatus.awaitingSignatureAtDelivery:
                case OrderFulfillmentStatus.awaitingPaymentAtDelivery:
                case OrderFulfillmentStatus.deliveryStarted:
                case OrderFulfillmentStatus.awaitingSignatureForConfirmation:
                case OrderFulfillmentStatus.delivered: {
                    return 'Delivery'
                }


                default: {
                    return ""
                }
            }
        } else return ''
    }

    const [name,] = useState<string>(getName());

    return <>
        {orderImage.image.metadata && orderImage.image.metadata?.length > 0 && <div
            className={`${name === 'Pickup' ? 'text-[#175BCC] bg-[#E0F0FF]' : 'text-[#4F4700] bg-[#FFEF9D]'} text-[12px] leading-[16px] absolute top-2 left-2 bg-white rounded-full h-[28px] content-center px-[10px]`}>
            {name}
        </div>}
    </>
}