import React from "react";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import OrderFulfillmentStatusBadge from "../../../../../../components/orderFulfillmentStatusBadge";
import {getStateAcronym} from "../../../../../../utils/getStateAcronym";
import {dateTimeRangeToString} from "../../../../../../utils/calendar/dateTimeRangeToString";

interface Props {
    order: OrderDetails
}

export const DirectionsSection = ({order}: Props) => {

    return <div key={'order-directions'} className={'flex flex-col border-b pb-6 md:pb-0 md:border-none'}>
        <section
            className={'flex flex-row flex-wrap w-full pb-3 md:pb-1 gap-x-4 gap-y-2 items-center justify-between md:justify-normal'}>
            <section className={'flex flex-row gap-2 items-center'}>
                <p className={'text-[#222222] text-[20px] leading-[28px] lg:text-[24px] lg:leading-[32px] font-medium'}> {order.originAddress?.locality}, {getStateAcronym(order.originAddress?.state ?? '')}</p>

                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.1665 10H15.8332" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M10 4.16602L15.8333 9.99935L10 15.8327" stroke="#222222" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <p className={'text-[#222222] text-[20px] leading-[28px] lg:text-[24px] lg:leading-[32px] font-medium'}> {order.destinationAddress?.locality}, {getStateAcronym(order.destinationAddress?.state ?? '')}</p>
            </section>

            <OrderFulfillmentStatusBadge order={order} sm={true}/>
        </section>

        <section className={'flex flex-col gap-1 md:flex-row'}>
            <p className={'text-[#717171] text-[16px] leading-[24px] font-normal'}>Scheduled pickup:</p>
            <p className={'text-[#717171] text-[16px] leading-[24px] font-normal'}>{dateTimeRangeToString(order.pickupDate, order.appointmentTime, order.appointmentSpecificDate)}</p>
        </section>

    </div>
}