import {zodResolver} from "@hookform/resolvers/zod";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel} from "src/components/form";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import * as z from "zod";
import {SpinnerIcon} from "../../../../../../../../media/icons/custom/spinnerIcon";
import {handleResponseError} from "../../../../../../../../utils/errorHandlers/handleResponseError";
import routes from "../../../../../../../../utils/defaults/routes";
import {useNavigate} from "react-router-dom";
import {getOrder} from "../../../../../../../../utils/apiCalls/orderDetails/getOrder";
import {isInstanceOf} from "../../../../../../../../utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "../../../../../../../../utils/types/errorMessage";
import {OrderDetails} from "../../../../../../../../utils/types/structures/orderDetails";
import editSingleItemSchema from "../../../../../../../../utils/zodSchemas/edit-single-item-schema";
import useOrderInventory, {
    ExtendedOrderItem
} from "../../../../../../../../utils/zustandStores/orderDetailsInventory/orderInventory";
import {CustomItem} from "../../../../../../../../utils/types/structures/customItem";
import {
    deleteOrderCustomItems
} from "../../../../../../../../utils/apiCalls/orderDetails/customItems/deleteOrderCustomItems";
import {deleteOrderItems} from "../../../../../../../../utils/apiCalls/orderDetails/orderItems/deleteOrderItems";
import {OrderItem} from "../../../../../../../../utils/types/structures/orderItem";
import {getOrderItems} from "../../../../../../../../utils/apiCalls/orderDetails/orderItems/getOrderItems";
import {getOrderCustomItems} from "../../../../../../../../utils/apiCalls/orderDetails/customItems/getOrderCustomItems";
import {
    getExtendedItemDestinationMetadata,
    getExtendedItemOriginMetadata
} from "../../../../../../../../utils/getItemMetadata";
import {Input} from "../../../../../../../../components/input";
import {Textarea} from "../../../../../../../../components/textArea";
import {atOriginConditions, atOriginNotesPackingContent} from "./carrier-notes-predefined-content";
import {updateOrderItems} from "../../../../../../../../utils/apiCalls/orderDetails/orderItems/updateOrderItems";
import {
    updateOrderCustomItems
} from "../../../../../../../../utils/apiCalls/orderDetails/customItems/updateOrderCustomItems";
import {createOriginMetadata} from "../../../../../../../../utils/apiCalls/metadata/createOriginMetadata";

interface Props {
    data: ExtendedOrderItem
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function EditSingleItem({data, setOrder, order}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isOnDeleteLoading, setOnDeleteLoading] = useState<boolean>(false);

    const {close} = useSheet()
    const navigate = useNavigate()
    const {initExtendedItems} = useOrderInventory();

    const onMutateItem = async () => {
        const res = await getOrder(order.id)
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate(routes.login))
        } else {
            setOrder(res)
        }
    }

    const onDeleteHandle = async (extendedItem: ExtendedOrderItem) => {
        setOnDeleteLoading(true)

        if (isInstanceOf<CustomItem>(extendedItem.item, 'description')) await deleteOrderCustomItems(order.id, [extendedItem.item.id])
        else await deleteOrderItems(order.id, [extendedItem.item.id])

        let currentOrderItems: OrderItem[] = []
        let currentCustomItems: CustomItem[] = []

        const orderItems = await getOrderItems(order.id);
        if (isInstanceOf<ErrorMessage>(orderItems, 'message')) {
            handleResponseError(orderItems)
        } else currentOrderItems = orderItems

        const customItems = await getOrderCustomItems(order.id);
        if (isInstanceOf<ErrorMessage>(customItems, 'message')) {
            handleResponseError(customItems)
        } else currentCustomItems = customItems

        initExtendedItems(currentOrderItems, currentCustomItems)
        const res = await getOrder(order.id)
        if (isInstanceOf<ErrorMessage>(res, 'messsage')) {
            handleResponseError(res)
        } else setOrder(res)

        await onMutateItem()
        setOnDeleteLoading(false)
        close()
    }

    const form = useForm<z.infer<typeof editSingleItemSchema>>({
        defaultValues: {
            count: data.item.count,
            notesAtOrigin: getExtendedItemOriginMetadata(data),
            notesAtDestination: getExtendedItemDestinationMetadata(data),
        },
        resolver: zodResolver(editSingleItemSchema),
        shouldFocusError: false,
    });

    async function onSubmit(values: z.infer<typeof editSingleItemSchema>) {
        setLoading(true)

        const itemCount = values.count
        const notesAtOrigin = values.notesAtOrigin

        if (isInstanceOf<OrderItem>(data.item, 'itemId')) {
            const currentItem = data.item as OrderItem
            const updatedItem: OrderItem = {
                ...currentItem,
                count: itemCount
            }
            const updatedOrderItems = await updateOrderItems(order.id, [updatedItem])
            if (isInstanceOf<ErrorMessage>(updatedOrderItems, 'message')) handleResponseError(updatedOrderItems, () => navigate('/'))

        } else {
            if (isInstanceOf<CustomItem>(data.item, 'description')) {
                const currentItem = data.item as CustomItem
                const updatedItem: CustomItem = {
                    ...currentItem,
                    count: itemCount
                }

                const updatedCustomItems = await updateOrderCustomItems(order.id, [updatedItem])
                if (isInstanceOf<ErrorMessage>(updatedCustomItems, 'message')) handleResponseError(updatedCustomItems, () => navigate('/'))
            }
        }

        if (data.item.id) {
            let itemType: 'orderItem' | 'customItem' = 'orderItem'
            if (isInstanceOf<CustomItem>(data.item, 'description')) itemType = 'customItem';
            await createOriginMetadata(data.item.id as string, notesAtOrigin, itemType)
        }

        let currentOrderItems: OrderItem[] = []
        let currentCustomItems: CustomItem[] = []

        const orderItems = await getOrderItems(order.id);
        if (isInstanceOf<ErrorMessage>(orderItems, 'message')) handleResponseError(orderItems, () => navigate('/'));
        else currentOrderItems = orderItems

        const customItems = await getOrderCustomItems(order.id);
        if (isInstanceOf<ErrorMessage>(customItems, 'message')) handleResponseError(customItems, () => navigate('/'));
        else currentCustomItems = customItems

        initExtendedItems(currentOrderItems, currentCustomItems)

        await onMutateItem()
        setLoading(false)
        close()
    }

    return <div className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>

        <Form {...form}>
            <form
                autoComplete={"off"}
                className="flex-1 flex flex-col gap-4"
                onSubmit={form.handleSubmit(onSubmit)}
            >

                <section className={'flex-1 flex flex-col gap-6 px-6 pt-6 relative'}>

                    <div className={'flex flex-row items-center gap-4'}>
                        <div className={'flex-1 flex flex-col gap-2 h-[72px]'}>
                            <p className={'font-medium text-foreground text-[14px] leading-[16px]'}>Item name</p>
                            <Input
                                data-testid={'item-name'}
                                placeholder={"Item name"}
                                disabled={true}
                                value={data.name}
                                className={`text-base text-foreground w-full h-[48px]`}/>
                        </div>

                        <div className={'flex-none w-[64px] flex flex-col gap-2 h-[72px]'}>
                            <FormField
                                control={form.control}
                                data-testid={'item-count'}
                                name="count"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel
                                            className={'flex h-4 font-medium text-foreground text-[14px] leading-[16px]'}>Quantity</FormLabel>

                                        <FormControl>
                                            <Input
                                                {...field}
                                                placeholder={''}
                                                inputMode={'numeric'}
                                                min={1}
                                                className={`text-center text-base ${form.getFieldState('count').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} flex-1`}/>
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>


                    <div className={'flex flex-col gap-1'}>
                        <p className={'text-foreground text-[14px] leading-[16px] font-medium'}>Notes at Origin</p>
                        <p className={'text-[#717171] text-[14px] leading-[20px] font-normal mb-1'}>
                            {'Describe the item\'s condition, packing, or other details, or click below for quick copy-paste options.'}
                        </p>

                        <FormField
                            control={form.control}
                            name="notesAtOrigin"
                            data-testid={'notes-at-origin'}
                            render={({field}) => (
                                <FormItem>
                                    <FormControl>
                                        <Textarea
                                            {...field}
                                            placeholder={'Leave comments here...'}
                                            className={`h-fit min-h-[120px] text-base ${form.getFieldState('count').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} flex-1`}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex flex-col gap-4'}>
                        <section className={'flex flex-col gap-3'}>
                            <p className={'text-foreground text-[14px] leading-[16px] font-medium'}>Packing:</p>
                            <ul className={'flex flex-row flex-wrap gap-2'}>
                                {atOriginNotesPackingContent.map(el =>
                                    <li data-testid={`notes-at-origin-${el.title}`}
                                        className={'rounded-full cursor-pointer bg-[#f2f2f2] py-[10px] px-[12px] text-foreground text-[14px] leading-[16px] font-medium'}
                                        onClick={() => {
                                            const currentValue = form.getValues('notesAtOrigin')
                                            const shouldPlaceComma = currentValue === undefined || currentValue.length > 0
                                            form.setValue('notesAtOrigin', currentValue + `${!shouldPlaceComma ? `${el.value}` : `, ${el.value}`}`)
                                        }}
                                        key={el.title}>
                                        {el.title}
                                    </li>)}
                            </ul>
                        </section>

                        <section className={'flex flex-col gap-3'}>
                            <p className={'text-foreground text-[14px] leading-[16px] font-medium'}>Conditions:</p>
                            <ul className={'flex flex-row flex-wrap gap-2'}>
                                {atOriginConditions.map(el =>
                                    <li data-testid={`notes-at-destination-${el.title}`}
                                        className={'rounded-full cursor-pointer bg-[#f2f2f2] py-[10px] px-[12px] text-foreground text-[14px] leading-[16px] font-medium'}
                                        onClick={() => {
                                            const currentValue = form.getValues('notesAtOrigin')
                                            const shouldPlaceComma = currentValue === undefined || currentValue.length > 0
                                            form.setValue('notesAtOrigin', currentValue + `${!shouldPlaceComma ? `${el.value}` : `, ${el.value}`}`)
                                        }}
                                        key={el.title}>
                                        {el.title}
                                    </li>)}
                            </ul>
                        </section>
                    </div>
                </section>

                <section
                    className={`flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4`}>
                    <Button size={'lg'}
                            data-testid={'on-delete'}
                            type={'button'}
                            disabled={isOnDeleteLoading || isLoading}
                            variant={'outline'}
                            className={'relative w-full hover:bg-white hover:text-[#c6241d] border-[#c6241d] text-center'}
                            onClick={async () => await onDeleteHandle(data)}>

                        <SpinnerIcon
                            className={`${isOnDeleteLoading ? 'visible' : 'invisible'} fill-[#c6241d] absolute`}
                            size={20}/>
                        <p className={`${isOnDeleteLoading ? 'invisible' : 'visible'} px-5 text-center text-[#c6241d] text-[16px] leading-[20px] font-medium`}>Delete</p>
                    </Button>

                    <Button
                        size={'lg'}
                        variant={'default'}
                        type={'submit'}
                        data-testid={'on-save'}
                        disabled={isOnDeleteLoading || isLoading}
                        className={'w-full text-base font-medium leading-tight relative'}>
                        <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                     size={20}/>
                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                    </Button>
                </section>
            </form>
        </Form>
    </div>
}