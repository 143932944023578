import React from "react";
import {Button} from "src/components/button";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {InventoryStatus} from "src/utils/enums/inventoryStatus";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import UpdateItems from "../../../../layout/popUps/sheet/content/orderDetails/updateItems/updateItems";
import {OrderFulfillmentStatus} from "../../../../../../utils/enums/orderFulfillmentStatus";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
    isLoading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function InventoryButtonSet({order, setOrder}: Props) {
    const {open} = useSheet();
    const {setCurrentExtendedItems, previousExtendedItems} = useOrderInventory();

    return <Button
        data-testid={'on-manage-inventory-handle'}
        disabled={order?.inventoryStatus === InventoryStatus.readOnly || order?.fulfillmentStatus !== OrderFulfillmentStatus.surveyAtPickup}
        onClick={() => {
            setCurrentExtendedItems(previousExtendedItems)
            open('Manage inventory', <UpdateItems setOrder={setOrder} order={order}/>)
        }}
        className={`text-[16px] leading-[20px] md:text-[14px] font-[500] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
    >Manage Inventory</Button>
}