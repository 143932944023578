import type {ReactNode} from "react";
import React, {useEffect, useState} from "react";
import type {UseFormReturn} from "react-hook-form";
import {FormControl, FormField, FormItem} from "src/components/form";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";
import PropertySize from "./propertyFields/propertySize";
import SquareFootage from "./propertyFields/squareFootage";
import UnitSize from "./propertyFields/unitSize";
import ApartmentFloor from "./propertyFields/apartment/apartmentFloor";
import AppartmentAccess from "./propertyFields/apartment/appartmentAccess";
import StorageFloor from "./propertyFields/storage/storageFloor";
import StorageAccess from "./propertyFields/storage/storageAccess";
import CommercialFloorNumber from "./propertyFields/commercial/commercialFloorNumber";
import CommercialAccess from "./propertyFields/commercial/commercialAccess";
import NumberStories from "./propertyFields/house/numberStories";
import HouseAccess from "./propertyFields/house/houseAccess";
import {Address} from "../../types/structures/Address";

interface Props {
    address: Address | null;

    form: UseFormReturn<{
        access: string;
        floorNumber: string;
        googleMapAddress: string;
        manualApt?: string;
        manualCity: string;
        manualCountry: string;
        manualState: string;
        manualStreetAddress: string;
        manualZip: string;

        numberStories: string;
        propertySize: string;
        propertyType: string;
        squareFootage: string;
        unitNumber: string;
        unitSize: string;
    }>;
    isDashboard: boolean;
}

export function PropertyUI({address, form, isDashboard}: Props) {
    const [propertyFields, setPropertyFields] = useState<ReactNode[]>([]);

    const switchPropertyFields = () => {
        switch (form.getValues("propertyType")) {
            case "APARTMENT": {
                setPropertyFields([
                    <PropertySize form={form} key="apartment_property_size"/>,
                    <ApartmentFloor form={form} key="apartment_floor_number"/>,
                    <AppartmentAccess form={form} key="apartment_access"/>,
                ]);
                break;
            }

            case "HOUSE": {
                setPropertyFields([
                    <PropertySize form={form} key="house_property_size"/>,
                    <NumberStories form={form} key="house_number_stories"/>,
                    <HouseAccess form={form} key="house_access"/>,
                ]);
                break;
            }

            case "STORAGE": {
                setPropertyFields([
                    <UnitSize form={form} key="storage_unitSize"/>,
                    <StorageFloor form={form} key="storage_floor_number"/>,
                    <StorageAccess form={form} key="storage_access"/>,
                ]);
                break;
            }

            case "COMMERCIAL": {
                setPropertyFields([
                    <SquareFootage form={form} key="commercial_square_footage"/>,
                    <CommercialFloorNumber form={form} key="commercial_floor_number"/>,
                    <CommercialAccess form={form} key="commercial_access"/>,
                ]);
                break;
            }

            default: {
                setPropertyFields([
                    <PropertySize form={form} key="apartment_property_size"/>,
                    <ApartmentFloor form={form} key="apartment_floor_number"/>,
                    <AppartmentAccess form={form} key="apartment_access"/>,
                ]);
                break;
            }
        }
    };

    useEffect(() => {
        switchPropertyFields();
    }, []);

    useEffect(() => {
        if (address) {
            switchPropertyFields();
        }
    }, [address]);

    return (
        <div className={`grid grid-cols-2  gap-y-4 gap-x-3`}>
            <section className={'flex flex-col gap-2'}>
                <p className={`text-[14px] leading-[16px] font-medium ${
                    form.getFieldState("propertyType").invalid
                        ? "text-[#C6241D] outline-[#C6241D] outline-2"
                        : ""
                }`}>Property type</p>
                <FormField
                    control={form.control}
                    name="propertyType"
                    render={({field}) => (
                        <FormItem className="flex-1">
                            <Select
                                onValueChange={(e) => {
                                    form.setValue("numberStories", "");
                                    form.setValue("propertySize", "");
                                    form.setValue("squareFootage", "");
                                    form.setValue("unitSize", "");
                                    form.setValue("floorNumber", "");
                                    form.setValue("access", "");
                                    form.setValue("propertyType", e);

                                    form.clearErrors("propertyType");
                                    form.clearErrors("numberStories");
                                    form.clearErrors("propertySize");
                                    form.clearErrors("squareFootage");
                                    form.clearErrors("unitSize");
                                    form.clearErrors("floorNumber");
                                    form.clearErrors("access");
                                    switchPropertyFields();
                                }}
                                value={field.value}
                            >
                                <FormControl>
                                    <SelectTrigger data-testid={'property-type'}
                                        className={`${
                                            form.getFieldState("propertyType").invalid
                                                ? "text-[#C6241D] border-[#C6241D] border-2"
                                                : ""
                                        }`}
                                    >
                                        <div
                                            className={`${
                                                form.getValues("propertyType") === ""
                                                    ? "text-[#717171] pl-1"
                                                    : "text-marco_default_foreground"
                                            }
                  ${form.getFieldState("propertyType").invalid ? "!text-[#C6241D]" : ""}
                  text-[16px] leading-[24px] font-normal`}
                                        >
                                            <SelectValue placeholder="Property type"/>
                                        </div>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectItem key={"HOUSE"} value={"HOUSE"}>
                                        House
                                    </SelectItem>
                                    <SelectItem key={"APARTMENT"} value={"APARTMENT"}>
                                        Apartment
                                    </SelectItem>
                                    <SelectItem key={"STORAGE"} value={"STORAGE"}>
                                        Storage
                                    </SelectItem>
                                    <SelectItem key={"COMMERCIAL"} value={"COMMERCIAL"}>
                                        Other
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </FormItem>
                    )}
                />
            </section>

            {propertyFields}
        </div>
    );
}
