import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function updateUploadStatus(fileId: string) {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/file/confirm`), {
            method: 'POST',
            headers: {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({fileId: fileId}),
        })

        if (res.ok) {
            return 200
        } else return await getDefinedError(res)

    } catch (e) {
        return otherError
    }
}