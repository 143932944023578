import DesktopContent from "src/pages/dashboarSection/layout/popUps/imageViewer/desktopContent";
import MobileContent from "src/pages/dashboarSection/layout/popUps/imageViewer/mobileContent";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";

export default function ImageViewer() {
    const {isImageViewerOpen} = useOrderAttachments()

    return <div className={`absolute h-dvh w-full z-50 bg-[#1A1A1A] ${isImageViewerOpen ? 'block' : 'hidden'} flex flex-col overflow-clip`}>
        <MobileContent/>
        <DesktopContent/>
    </div>
}