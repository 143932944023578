import React from "react";
import {OrderFulfillmentStatus} from "src/utils/enums/orderFulfillmentStatus";
import {OrderDetails, PaymentStatus} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
    sm?: boolean
}

export default function OrderFulfillmentStatusBadge({order, sm}: Props) {

    return <div
        className={`flex-none flex ${sm ? 'flex-wrap flex-row items-center gap-1' : 'flex-col lg:flex-row lg:items-center gap-1'}  h-fit w-fit`}>
        {
            order.fulfillmentStatus === OrderFulfillmentStatus.notAccepted &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#F2F2F2] px-[10px] text-[#717171] font-medium`}>
                Not Accepted
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.accepted && order.driver &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                Accepted
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.accepted && !order.driver &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FFD6A4] px-[10px] text-[#5E4200] font-medium`}>
                Driver Required
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.dispatched &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                Dispatched
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.arrivedAtPickup &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                Arrived at Pickup
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.surveyAtPickup &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                Survey Started
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.awaitingSignatureAtPickup &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FFEF9D] px-[10px] text-[#4F4700] font-medium`}>
                Awaiting Signature
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.awaitingPaymentAtPickup &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FFEF9D] px-[10px] text-[#4F4700] font-medium`}>
                Awaiting Payment
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.pickupStarted &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                Pickup Started
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.inTransit &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                In Transit
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.arrivedAtDelivery &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                Arrived at Delivery
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.surveyAtDelivery &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                Survey Started
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.awaitingSignatureAtDelivery &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FFEF9D] px-[10px] text-[#4F4700] font-medium`}>
                Awaiting Signature
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.awaitingPaymentAtDelivery &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FFEF9D] px-[10px] text-[#4F4700] font-medium`}>
                Awaiting Signature
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.deliveryStarted &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#E0F0FF] px-[10px] text-[#175BCC] font-medium`}>
                Delivery Started
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.awaitingSignatureForConfirmation &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FFEF9D] px-[10px] text-[#4F4700] font-medium`}>
                Awaiting Signature
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.delivered &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#CDFEE1] px-[10px] text-[#166C3B] font-medium`}>
                Delivered
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.cancelled &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#F2F2F2] px-[10px] text-[#717171] font-medium`}>
                Closed
            </section>
        }

        {
            order.paymentStatus === PaymentStatus.paymentDeclined &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FEDAD9] px-[10px] text-[#8E1F0B] font-medium`}>
                Payment Declined
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.forfeited &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FEDAD9] px-[10px] text-[#8E1F0B] font-medium`}>
                Forfeited
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.paymentDispute &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FEDAD9] px-[10px] text-[#8E1F0B] font-medium`}>
                Payment Dispute
            </section>
        }

        {
            order.fulfillmentStatus === OrderFulfillmentStatus.onHold &&
            <section
                className={`w-fit truncate rounded-full ${sm ? 'h-[28px] text-[12px] leading-[16px]' : 'h-[32px] text-[14px] leading-[20px]'} content-center bg-[#FEDAD9] px-[10px] text-[#8E1F0B] font-medium`}>
                On Hold
            </section>
        }
    </div>
}