import {AdvancedMarker, Map, useMap} from "@vis.gl/react-google-maps";
import React, {useEffect} from "react";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useDirections from "../../../../../../utils/zustandStores/useDirections";

interface Props {
    order: OrderDetails
}

export default function MapSection({order}: Props) {
    const {
        initDirections,
        originCoordinates,
        destinationCoordinates,
        directionsResult,
        directionsRenderer,
    } = useDirections()
    const map = useMap('order-directions');

    useEffect(() => {
        if (map && order) {
            initDirections(order.originAddress, order.destinationAddress).then(res => {
                if (res) {
                    directionsRenderer.setDirections(res.directionsResult);
                    directionsRenderer.setMap(map);
                    directionsRenderer.setOptions({
                        polylineOptions:
                            {
                                strokeColor: '#00442E',
                                strokeOpacity: 0.8,
                                strokeWeight: 2
                            }
                    })
                }
            })
        }
    }, [order, map]);

    return <section key={'map'} className={'lg:h-[calc(100vh-242px)] lg:sticky lg:top-6 lg:pb-6'}>
        <Map
            className={'w-full rounded-md h-[280px] md:h-[400px] lg:h-full'}
            id={'order-directions'}
            mapId={'3999fcb295fa34f1'}
            zoomControl={false}
            defaultCenter={{lat: 39.67096338359407, lng: -101.5601656163939}}
            defaultZoom={6}
            gestureHandling={'cooperative'}
            disableDefaultUI={true}
        >
            {directionsResult && <AdvancedMarker position={originCoordinates}>
                <DotIcon size={10} className={'fill-marcoDarkGreen'}/>
            </AdvancedMarker>}

            {directionsResult && <AdvancedMarker position={destinationCoordinates}>
                <SquareIcon size={10} className={'fill-marcoDarkGreen'}/>
            </AdvancedMarker>}
        </Map>
    </section>
}