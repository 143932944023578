"use client"

import * as React from "react"
import {DayPicker} from "react-day-picker"
import {ShevronLeft} from "src/media/icons/custom/shevron/shevronLeft";
import {ShevronRight} from "src/media/icons/custom/shevron/shevronRight";
import {Colors} from "src/utils/defaults/colors";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import {buttonVariants} from "../components/button"
import {cn} from "../utils/cn/cnHelper";

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
                      className,
                      classNames,
                      showOutsideDays = true,
                      ...props
                  }: CalendarProps) {

    const {width} = useViewport()

    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('', className)}
            classNames={{
                caption:
                    "h-[20px] flex justify-center relative items-center text-center",
                caption_label:
                    "text-marco_default_foreground text-[16] leading-[20px] font-[600]",
                month: "space-y-2",
                months:
                    "flex-1 flex flex-row items-center justify-between",
                nav: "space-x-1 flex items-center",
                nav_button: cn(
                    buttonVariants({variant: "icon"}),
                    "bg-transparent transition-all duration-200 rounded-full p-0 w-8 h-8 rounded-full hover:bg-[#EBEBEB]",
                ),
                nav_button_next: "absolute right-0 ",
                nav_button_previous: "absolute left-0",
                table: "w-fit border-collapse ",
                head_row: "flex flex-row justify-between ",
                head_cell:
                    "flex-1 aspect-square place-content-center text-muted-foreground text-xs font-semibold leading-none rounded-full max-w-[48px]",
                row: "flex flex-row w-full justify-between pt-[2px]",
                cell: cn(
                    `w-fit ${(width < 376) ? 'w-[40px] h-[40px]' : 'w-[48px] h-[48px]'} text-foreground relative text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-marcoOnHover`,
                    props.mode === "range"
                        ? "[&:has(>.day-range-end)]:rounded-r-full [&:has(>.day-range-start)]:rounded-l-full first:[&:has([aria-selected])]:bg-marcoOnHover last:[&:has([aria-selected])]:bg-marcoOnHover"
                        : "[&:has([aria-selected])]:bg-marcoOnHover"
                ),
                day: cn(
                    buttonVariants({variant: "ghost"}),
                    "rounded-full h-full w-full font-normal aria-selected:opacity-100 "
                ),
                day_range_start: "day-range-start",
                day_range_end: "day-range-end",
                day_selected:
                    "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground rounded-full !font-medium",
                day_today: "border-2 border-[#cccccc] text-neutral-800",
                day_outside:
                    "day-outside text-foreground opacity-90 ",
                day_disabled: "text-muted-foreground opacity-50",
                day_range_middle:
                    "aria-selected:bg-marcoOnHover aria-selected:text-foreground",
                day_hidden: "invisible",
                ...classNames,
            }}
            components={{
                IconLeft: () => (
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M6.78865 0.180309C7.05725 0.432135 7.07086 0.854016 6.81905 1.12263L2.2465 6L6.81905 10.8773C7.07086 11.146 7.05725 11.5679 6.78865 11.8197C6.52003 12.0715 6.09814 12.0579 5.84633 11.7893L0.846326 6.45596C0.605912 6.19952 0.605912 5.80048 0.846326 5.54404L5.84633 0.210709C6.09814 -0.0579047 6.52003 -0.0715047 6.78865 0.180309Z"
                              fill="#222222"/>
                    </svg>
                ),
                IconRight: () => (
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M0.210713 0.180314C0.479313 -0.0715126 0.901205 -0.0578994 1.15303 0.210713L6.15297 5.54404C6.39337 5.80046 6.39337 6.19951 6.15297 6.45595L1.15303 11.7893C0.901205 12.0579 0.479313 12.0715 0.210713 11.8197C-0.0578994 11.5678 -0.0715126 11.1459 0.180314 10.8774L4.75284 6L0.180314 1.12263C-0.0715126 0.854019 -0.0578994 0.432126 0.210713 0.180314Z"
                              fill="#222222"/>
                    </svg>

                ),
            }}
            {...props}
        />
    )
}

Calendar.displayName = "Calendar"

export {Calendar}
