import {OrderImageOwner} from "src/utils/enums/orderImageOwner";

export enum OrderImageStatus {
    uploaded = 'UPLOADED',
    waitingUpload = 'WAITING_UPLOAD'
}

export type OrderImage = {
    fileId: string,
    url: string,
    metadata: string | null,
    owner: OrderImageOwner,
    status: OrderImageStatus,
    createOn: string
}