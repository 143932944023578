import type {UseFormReturn} from "react-hook-form";
import React from "react";
import {FormControl, FormField, FormItem} from "src/components/form";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";

interface Props {
    form: UseFormReturn<{
        access: string;
        floorNumber: string;
        numberStories: string;
        propertySize: string;
        propertyType: string;
        squareFootage: string;
        unitNumber: string;
        unitSize: string;
    }>;
}

export default function NumberStoriesDelivery({form}: Props) {
    return (

        <section className={'flex flex-col gap-2'}>

            <p className={`text-[14px] leading-[16px] font-medium ${
                form.getFieldState("numberStories").invalid
                    ? "text-[#C6241D] outline-[#C6241D] outline-2"
                    : ""
            }`}>Stories</p>

            <FormField
                control={form.control}
                name="numberStories"
                render={({field}) => (
                    <FormItem className="flex-1">
                        <Select
                            onValueChange={(e) => {
                                form.setValue("numberStories", e);
                                form.clearErrors("numberStories");
                            }}
                            value={field.value}
                        >
                            <FormControl>
                                <SelectTrigger
                                    className={`${form.getFieldState("numberStories").invalid ? "text-[#C6241D] outline-[#C6241D] outline-2" : ""}`}
                                >
                                    <div
                                        className={`${
                                            form.getValues("numberStories") === ""
                                                ? "text-[#717171] pl-1"
                                                : "pl-1 text-marco_default_foreground"
                                        }
                  ${form.getFieldState("numberStories").invalid ? "!text-[#C6241D]" : ""}
                  text-[16px] leading-[24px] font-normal`}
                                    >
                                        <SelectValue placeholder="Stories"/>
                                    </div>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                <SelectItem key={"ONE_STORIES"} value={"ONE_STORIES"}>
                                    1 Story
                                </SelectItem>

                                <SelectItem key={"TWO_STORIES"} value={"TWO_STORIES"}>
                                    2 Stories
                                </SelectItem>

                                <SelectItem key={"THREE_STORIES"} value={"THREE_STORIES"}>
                                    3 Stories
                                </SelectItem>

                                <SelectItem key={"FOUR_STORIES"} value={"FOUR_STORIES"}>
                                    4+ Stories
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    </FormItem>
                )}
            />
        </section>


    );
}
