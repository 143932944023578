export enum OrderFulfillmentStatus {
    notAccepted = 'NOT_ACCEPTED',
    accepted = 'ACCEPTED',
    dispatched = 'DISPATCHED',
    arrivedAtPickup = 'ARRIVED_AT_PICKUP',
    surveyAtPickup = 'SURVEY_AT_PICKUP',
    awaitingSignatureAtPickup = 'AWAITING_SIGNATURE_AT_PICKUP',
    awaitingPaymentAtPickup = 'AWAITING_PAYMENT_AT_PICKUP',
    pickupStarted = 'PICKUP_STARTED',
    inTransit = 'IN_TRANSIT',
    arrivedAtDelivery = 'ARRIVED_AT_DELIVERY',
    surveyAtDelivery = 'SURVEY_AT_DELIVERY',
    awaitingSignatureAtDelivery = 'AWAITING_SIGNATURE_AT_DELIVERY',
    awaitingPaymentAtDelivery = 'AWAITING_PAYMENT_AT_DELIVERY',
    deliveryStarted = 'DELIVERY_STARTED',
    awaitingSignatureForConfirmation = 'AWAITING_SIGNATURE_FOR_CONFIRMATION',
    delivered = 'DELIVERED',
    cancelled = 'CANCELLED',
    forfeited = 'FORFEITED',
    paymentDispute = 'PAYMENT_DISPUTE',
    onHold = 'ON_HOLD'
}