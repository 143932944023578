import moment from "moment/moment";
import * as React from "react";
import {DateRange} from "react-day-picker";
import {useSearchParams} from "react-router-dom";
import {Button, buttonVariants} from "src/components/button";
import {Calendar} from "src/components/calendar";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import {ShevronDown} from "src/media/icons/custom/shevron/shevronDown";
import {cn} from "src/utils/cn/cnHelper";
import {Colors} from "src/utils/defaults/colors";
import getDatetoD from "src/utils/stringHandlers/calendarFilter/getDatetoD";
import getDatetoMD from "src/utils/stringHandlers/calendarFilter/getDatetoMD";
import getDatetoMDY from "src/utils/stringHandlers/calendarFilter/getDatetoMDY";
import useSearch from "src/utils/zustandStores/useSearch";


export function CalendarDateRangePicker() {
    const [searchParams, setSearchParams] = useSearchParams()

    const {response, setDate} = useSearch()

    const updateDate = (e: DateRange | undefined) => {
        if (!e) {
            searchParams.delete('startDate');
            searchParams.delete('endDate');
        }

        if (e?.from) {
            const startDate = moment(e.from, 'YYYY-MM-DD').format('YYYY-MM-DD')
            searchParams.set('startDate', startDate);
        }

        if (e?.to) {
            const endDate = moment(e.to, 'YYYY-MM-DD').format('YYYY-MM-DD')
            searchParams.set('endDate', endDate);
        }

        setSearchParams(searchParams)



    }



    return (
        <div className={cn("grid gap-2")}>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={"rounded"}
                        size={"tab"}
                        className={`
                        ${response.body.date !== undefined && 'outline outline-foreground outline-2 outline-offset-[-2px]'}
                        rounded-full flex gap-2 text-foreground text-[14px] leading-[20px] font-medium`}
                    >
                        {response.body.date?.from
                            ? (
                                response.body.date.to
                                    ? (
                                        response.body.date.to.getFullYear() === response.body.date.from.getFullYear()

                                            ? (response.body.date.to.getMonth() === response.body.date.from.getMonth()

                                                    ? <p className={'text-sm'}>
                                                        {getDatetoMD(response.body.date.from)} -{" "}
                                                        {getDatetoD(response.body.date.to)}
                                                    </p>

                                                    : <p className={'text-sm'}>
                                                        {getDatetoMD(response.body.date.from)} -{" "}
                                                        {getDatetoMD(response.body.date.to)}
                                                    </p>
                                            )

                                            : <p className={'text-foreground text-[14px] leading-[20px] font-medium'}>
                                                {getDatetoMDY(response.body.date.from)} -{" "}
                                                {getDatetoMDY(response.body.date.to)}
                                            </p>
                                    )

                                    : (
                                        <p className={'text-foreground text-[14px] leading-[20px] font-medium'}>{getDatetoMD(response.body.date.from)}</p>
                                    )
                            )
                            : (
                                <p className={'text-foreground text-[14px] leading-[20px] font-medium'}>Date</p>
                            )}
                        <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-fit h-fit p-6 rounded-md" align="start" sticky={'always'}>
                        <Calendar
                            initialFocus
                            mode="range"
                            defaultMonth={new Date()}
                            fromDate={new Date()}
                            selected={response.body.date}
                            onSelect={(e) => {
                                setDate(e);
                                updateDate(e)
                            }}
                            numberOfMonths={1}
                        />
                </PopoverContent>
            </Popover>
        </div>
    )
}