import {InventoryStatus} from "src/utils/enums/inventoryStatus";
import {OrderFulfillmentStatus} from "src/utils/enums/orderFulfillmentStatus";
import {OrderPacking} from "src/utils/enums/orderPacking";
import {OrderStatus} from "src/utils/enums/orderStatus";
import {Address} from "src/utils/types/structures/Address";
import {Member} from "src/utils/types/structures/member";

export enum AppointmentTime {
    afternoon = "AFTERNOON",
    evening = "EVENING",
    morning = "MORNING",
}

export enum PaymentStatus {
    notPaid = 'NOT_PAID',
    partiallyPaid = 'PARTIALLY_PAID',
    paid = 'PAID',
    paymentDeclined = 'PAYMENT_DECLINED',
    partiallyRefunded = 'PARTIALLY_REFUNDED',
    refunded = 'REFUNDED'
}

export type OrderDetails = {
    id: string
    originAddress?: Address | null
    destinationAddress?: Address | null

    pickupDate?: string | null
    appointmentSpecificDate: boolean
    appointmentTime: AppointmentTime

    deliveryDate?: string | null
    deliverySpecificDate: boolean
    deliveryTime?: AppointmentTime | null;

    distance?: number | null
    volume?: number | null
    tolls?: number | null
    fuel?: number | null
    packing?: OrderPacking | null
    status?: OrderStatus | null
    fulfillmentStatus?: OrderFulfillmentStatus
    paymentStatus?: PaymentStatus,
    driverId?: string | null
    rate?: number | null
    notes?: string | null
    customerFirstName?: string | null,
    customerLastName?: string | null,
    customerPhone?: string | null,
    customerEmail?: string | null,

    driver?: Member | null,
    sid: string,
    inventoryStatus: InventoryStatus,
}