import {ExtendedOrderItem} from "./zustandStores/orderDetailsInventory/orderInventory";

export const getExtendedItemOriginMetadata = (extendedItem: ExtendedOrderItem) => {
    if (extendedItem.item.originMetadata !== undefined && extendedItem.item.originMetadata?.NOTE !== undefined && extendedItem.item.originMetadata.NOTE.length > 0) {
        return extendedItem.item.originMetadata.NOTE[0]
    }

    return ''
}

export const getExtendedItemDestinationMetadata = (extendedItem: ExtendedOrderItem) => {
    if (extendedItem.item.destinationMetadata !== undefined && extendedItem.item.destinationMetadata?.NOTE !== undefined && extendedItem.item.destinationMetadata.NOTE.length > 0) {
        return extendedItem.item.destinationMetadata.NOTE[0]
    }

    return ''
}

export const isMetadatePresent = (extendedItem: ExtendedOrderItem) => {
    if (extendedItem.item.originMetadata !== undefined && extendedItem.item.originMetadata?.NOTE !== undefined && extendedItem.item.originMetadata.NOTE.length > 0) {
        return true
    }

    return extendedItem.item.destinationMetadata !== undefined && extendedItem.item.destinationMetadata?.NOTE !== undefined && extendedItem.item.destinationMetadata.NOTE.length > 0;
}

export const isDestinationMetadataPresent = (extendedItem: ExtendedOrderItem) => {
    return extendedItem.item.destinationMetadata !== undefined && extendedItem.item.destinationMetadata?.NOTE !== undefined && extendedItem.item.destinationMetadata.NOTE.length > 0;
}