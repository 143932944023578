import React from "react";
import {ScrollArea, ScrollBar} from "src/components/scrollArea";
import Tabs from "src/pages/dashboarSection/orderDetails/buttons/buttonLayout/tabs";
import {OrderDetailsTabSections} from "src/pages/dashboarSection/orderDetails/orderDetailsLayout";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import {useNavigate} from "react-router-dom";
import MoreActionsHandler from "../buttonMoreActionsHandle/moreActionsHandler";

interface Props {
    order: OrderDetails
    currentActiveTab: OrderDetailsTabSections
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function MobileDetailsHeader({order, currentActiveTab, setOrder}: Props) {
    const {isScrolled} = useViewport();

    const navigate = useNavigate()

    return <section
        className={`${!isScrolled && 'shadow-light'} flex-none px-padding sticky top-0 pt-8  md:shadow-none pb-6 bg-background z-30 flex flex-col gap-4`}>

        <div className={'flex flex-row justify-between items-center w-full'}>

            <section className={'flex-1 flex flex-row items-center'}>
                <span
                    className={'cursor-pointer w-10 h-10 rounded-full grid items-center place-content-center bg-[#F7F7F7]'}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(`/loads/booked`)
                    }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10H4.1665" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M9.99984 15.8327L4.1665 9.99935L9.99984 4.16602" stroke="#222222" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>

                <p className={'flex-1 text-center !text-[#222222] !text-[18px] !leading-[28px] !font-semibold !no-underline'}>{`Load #${order.sid}`}</p>
            </section>


            <MoreActionsHandler setOrder={setOrder} order={order}/>
        </div>

        <ScrollArea className={"w-[95vw] sm:w-full"}>
            <Tabs order={order} currentActiveTab={currentActiveTab}/>
            <ScrollBar orientation="horizontal" className={'hidden'}/>
        </ScrollArea>
    </section>
}