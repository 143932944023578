import type {UseFormReturn} from "react-hook-form";
import type {SetValue} from "use-places-autocomplete";
import React, {useEffect, useState} from "react";
import {AddressToValidateRequest} from "../addressToValidateRequest";
import validateAddress from "../validateAddress";
import {isInstanceOf} from "../../errorHandlers/isInstanceOf";
import {AddressToValidateResponse200, ConfirmationLevel} from "../addressToValidateResponse200";
import {FormControl, FormField, FormItem} from "../../../components/form";
import {CustomInput} from "../CustomInput";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../../components/select";
import {states} from "../USStates";
import {Button} from "src/components/button";
import {SpinnerIcon} from "../../../media/icons/custom/spinnerIcon";

interface Props {
    form: UseFormReturn<{
        access: string;

        floorNumber: string;
        googleMapAddress: string;
        manualApt?: string;
        manualCity: string;
        manualCountry: string;
        manualState: string;
        manualStreetAddress: string;
        manualZip: string;

        numberStories: string;
        propertySize: string;
        propertyType: string;
        squareFootage: string;
        unitNumber: string;
        unitSize: string;
    }>;
    isManualAddressFormActive: boolean;
    setManualAddressFormActive: React.Dispatch<React.SetStateAction<boolean>>;
    setValue: SetValue;
}

export default function ManualAddrInput({
                                            form,
                                            isManualAddressFormActive,
                                            setManualAddressFormActive,
                                            setValue,
                                        }: Props) {

    useEffect(() => {
        if (isManualAddressFormActive) {
            form.setValue("manualStreetAddress", "");
            form.setValue("manualApt", "");
            form.setValue("manualCity", "");
            form.setValue("manualState", "");
            form.setValue("manualZip", "");
            form.setValue("manualCountry", "US");

            form.clearErrors("manualStreetAddress");
            form.clearErrors("manualApt");
            form.clearErrors("manualCity");
            form.clearErrors("manualState");
            form.clearErrors("manualZip");
            form.clearErrors("manualCountry");
        }
    }, [isManualAddressFormActive]);


    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        form.clearErrors("manualStreetAddress");
        form.clearErrors("manualApt");
        form.clearErrors("manualCity");
        form.clearErrors("manualState");
        form.clearErrors("manualZip");
        form.clearErrors("manualCountry");

        const manualCountry = form.getValues("manualCountry");
        const manualStreetAddress = form.getValues("manualStreetAddress");
        const manualApt = form.getValues("manualApt");
        const manualCity = form.getValues("manualCity");
        const manualState = form.getValues("manualState");
        const manualZip = form.getValues("manualZip");

        if (
            manualStreetAddress !== "" &&
            manualCity !== "" &&
            manualState !== "" &&
            manualZip !== "" &&
            manualCountry !== ""
        ) {
            const actualAddress = `${manualStreetAddress},${manualApt === undefined || manualApt.length === 0 ? "" : ` ${manualApt},`} ${manualCity}, ${manualState} ${manualZip}`;

            const addressToValidate: AddressToValidateRequest = {
                address: {
                    addressLines: [`${manualStreetAddress}`],
                    administrativeArea: manualState,
                    locality: manualCity,
                    postalCode: manualZip,
                    regionCode: manualCountry,
                },
            };

            const res = await validateAddress(addressToValidate);

            if (isInstanceOf<AddressToValidateResponse200>(res, "result")) {
                const addressVerdict = res.result.address.addressComponents.find(
                    (el) => el.componentType === "route",
                );
                const cityVerdict = res.result.address.addressComponents.find(
                    (el) => el.componentType === "locality",
                );
                const stateVerdict = res.result.address.addressComponents.find(
                    (el) => el.componentType === "administrative_area_level_1",
                );
                const zipVerdict = res.result.address.addressComponents.find(
                    (el) => el.componentType === "postal_code",
                );
                const countryVerdict = res.result.address.addressComponents.find(
                    (el) => el.componentType === "country",
                );

                if (
                    addressVerdict &&
                    cityVerdict &&
                    (cityVerdict.confirmationLevel === ConfirmationLevel.confirmed ||
                        cityVerdict.confirmationLevel === ConfirmationLevel.plausible) &&
                    stateVerdict &&
                    stateVerdict.confirmationLevel === ConfirmationLevel.confirmed &&
                    zipVerdict &&
                    zipVerdict.confirmationLevel === ConfirmationLevel.confirmed &&
                    countryVerdict &&
                    countryVerdict.confirmationLevel === ConfirmationLevel.confirmed
                ) {
                    setValue(actualAddress, false);
                    form.setValue("googleMapAddress", actualAddress);
                    form.clearErrors("googleMapAddress");
                    setManualAddressFormActive(false);
                } else {
                    if (
                        !addressVerdict ||
                        (addressVerdict.confirmationLevel !== ConfirmationLevel.confirmed &&
                            addressVerdict.confirmationLevel !== ConfirmationLevel.plausible)
                    ) {
                        form.setError("manualStreetAddress", {message: "Invalid field"});
                    }

                    if (
                        !cityVerdict ||
                        cityVerdict.confirmationLevel !== ConfirmationLevel.confirmed
                    ) {
                        form.setError("manualCity", {message: "Invalid field"});
                    }

                    if (
                        !stateVerdict ||
                        stateVerdict.confirmationLevel !== ConfirmationLevel.confirmed
                    ) {
                        form.setError("manualState", {message: "Invalid field"});
                    }

                    if (
                        !zipVerdict ||
                        zipVerdict.confirmationLevel !== ConfirmationLevel.confirmed
                    ) {
                        form.setError("manualZip", {message: "Invalid field"});
                    }

                    if (
                        !countryVerdict ||
                        countryVerdict.confirmationLevel !== ConfirmationLevel.confirmed
                    ) {
                        form.setError("manualCountry", {message: "Invalid field"});
                    }
                }
            }
        } else {
            if (!manualStreetAddress || manualStreetAddress === "") {
                form.setError("manualStreetAddress", {message: "Required"});
            }

            if (!manualCity || manualCity === "") {
                form.setError("manualCity", {message: "Required"});
            }

            if (!manualState || manualState === "") {
                form.setError("manualState", {message: "Required"});
            }

            if (!manualZip || manualZip === "") {
                form.setError("manualZip", {message: "Required"});
            }

            if (!manualCountry || manualCountry === "") {
                form.setError("manualCountry", {message: "Required"});
            }
        }
        setLoading(false)
    };

    return (
        <section
            className={`flex flex-col gap-4 ${!isManualAddressFormActive && "hidden"}`}
        >


            <FormField
                control={form.control}
                name="manualStreetAddress"
                render={({field}) => (
                    <FormItem className="flex-1">
                        <FormControl>
                            <CustomInput
                                {...field}
                                data-testid={'manual-street-address'}
                                autoComplete="off"
                                isempty={
                                    form.getValues("manualStreetAddress") === ""
                                        ? "true"
                                        : "false"
                                }
                                isinvalid={
                                    form.getFieldState("manualStreetAddress").invalid
                                        ? "true"
                                        : "false"
                                }
                                label="Street address"
                                onChange={(e) => {
                                    form.setValue("manualStreetAddress", e.target.value);
                                    form.clearErrors("manualStreetAddress");
                                }}
                            />
                        </FormControl>
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="manualApt"
                render={({field}) => (
                    <FormItem className="flex-1">
                        <FormControl>
                            <CustomInput
                                {...field}
                                data-testid={'manual-apt'}
                                autoComplete="off"
                                isempty={form.getValues("manualApt") === "" ? "true" : "false"}
                                isinvalid={
                                    form.getFieldState("manualApt").invalid ? "true" : "false"
                                }
                                label="Apt, Suite, etc"
                                onChange={(e) => {
                                    form.setValue("manualApt", e.target.value);
                                    form.clearErrors("manualApt");
                                }}
                            />
                        </FormControl>
                    </FormItem>
                )}
            />

            <div className="flex flex-row justify-between gap-3">
                <FormField
                    control={form.control}
                    name="manualCity"
                    render={({field}) => (
                        <FormItem className="flex-1">
                            <FormControl>
                                <CustomInput
                                    {...field}
                                    data-testid={'manual-city'}
                                    autoComplete="off"
                                    isempty={
                                        form.getValues("manualCity") === "" ? "true" : "false"
                                    }
                                    isinvalid={
                                        form.getFieldState("manualCity").invalid ? "true" : "false"
                                    }
                                    label="City"
                                    onChange={(e) => {
                                        form.setValue("manualCity", e.target.value);
                                        form.clearErrors("manualCity");
                                    }}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />

                <section className={'flex flex-col gap-2 flex-1'}>
                    <p className={'text-[#222222] text-[14px] leading-[20px] font-medium'}>State</p>

                    <FormField
                        control={form.control}
                        name="manualState"
                        render={({field}) => (
                            <FormItem>
                                <Select
                                    data-testid={'manual-state'}
                                    onValueChange={(e) => {
                                        form.setValue("manualState", e);
                                        form.clearErrors("manualState");
                                    }}
                                    value={field.value ?? ""}
                                >
                                    <FormControl>
                                        <SelectTrigger
                                            className={`${
                                                form.getFieldState("manualState").invalid
                                                    ? "text-[#C6241D] border-[#C6241D] border-2"
                                                    : ""
                                            }`}
                                        >
                                            <div
                                                className={`${
                                                    form.getValues("manualState") === ""
                                                        ? "text-slate-500 pl-1"
                                                        : "pl-1 text-marco_default_foreground"
                                                }
                  ${form.getFieldState("manualState").invalid ? "!text-[#C6241D]" : ""}
                  text-base font-normal leading-7`}
                                            >
                                                <SelectValue placeholder="State"/>
                                            </div>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        {states.map((el) => (
                                            <SelectItem key={el.acronym} value={el.acronym}>
                                                {el.name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </FormItem>
                        )}
                    />
                </section>
            </div>

            <div className="flex flex-row justify-between gap-3">
                <FormField
                    control={form.control}
                    name="manualZip"
                    render={({field}) => (
                        <FormItem className="flex-1">
                            <FormControl>
                                <CustomInput
                                    {...field}
                                    data-testid={'manual-zip'}
                                    autoComplete="off"
                                    isempty={
                                        form.getValues("manualZip") === "" ? "true" : "false"
                                    }
                                    isinvalid={
                                        form.getFieldState("manualZip").invalid ? "true" : "false"
                                    }
                                    label="Zip"
                                    onChange={(e) => {
                                        form.setValue("manualZip", e.target.value);
                                        form.clearErrors("manualZip");
                                    }}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />


                <section className={'flex flex-col gap-2 flex-1'}>
                    <p className={'text-[#222222] text-[14px] leading-[20px] font-medium'}>Country</p>

                    <FormField
                        control={form.control}
                        name="manualCountry"
                        render={({field}) => (
                            <FormItem className="flex-1 relative">
                                <Select
                                    data-testid={'manual-country'}
                                    onValueChange={(e) => {
                                        form.setValue("manualCountry", e);
                                        form.clearErrors("manualCountry");
                                    }}
                                    value={field.value ?? ""}
                                >
                                    <FormControl>
                                        <SelectTrigger
                                            className={`${
                                                form.getFieldState("manualCountry").invalid
                                                    ? "text-[#C6241D] border-[#C6241D] border-2"
                                                    : ""
                                            }`}
                                        >
                                            <div
                                                className={`${
                                                    form.getValues("manualCountry") === ""
                                                        ? "text-slate-500 pl-1"
                                                        : "pl-1 text-marco_default_foreground"
                                                }
                  ${form.getFieldState("manualCountry").invalid ? "!text-[#C6241D]" : ""}
                  text-base font-normal leading-7`}
                                            >
                                                <SelectValue placeholder="Country"/>
                                            </div>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem key="USA" value="US">
                                            USA
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormItem>
                        )}
                    />
                </section>
            </div>

            <section
                className={`flex-none absolute w-full left-0 bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4`}>
                <Button
                    size={'lg'}
                    data-testid={'on-manual-cancel'}
                    type={'button'}
                    variant={'outline'}
                    className={'w-full px-5 text-[#222222] text-center text-[16px] leading-[20px] font-medium'}

                    onClick={() => {
                        form.setValue("manualZip", "");
                        form.setValue("manualCity", "");
                        form.setValue("manualStreetAddress", "");
                        form.setValue("manualCountry", "");
                        form.setValue("manualApt", "");
                        form.setValue("manualState", "");

                        setManualAddressFormActive(false);
                    }}
                >
                    Cancel
                </Button>

                <Button
                    size={'lg'}
                    data-testid={'on-manual-confirm'}
                    variant={'default'}
                    type={'button'}
                    onClick={handleSubmit}
                    className={'w-full text-base font-medium leading-tight relative'}>
                    <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                 size={20}/>
                    <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Confirm
                        address</p>
                </Button>
            </section>
        </section>
    );
}
