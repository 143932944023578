import React, {useEffect, useState} from "react";
import {Button, buttonVariants} from "src/components/button";
import {AppointmentTime, OrderDetails} from "src/utils/types/structures/orderDetails";
import dayjs from "dayjs";
import {DayModifiers, DayPicker} from "react-day-picker";
import {postDeliveryDate} from "../../../../../../../utils/apiCalls/postDeliveryDate";
import {isInstanceOf} from "../../../../../../../utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "../../../../../../../utils/types/errorMessage";
import {handleResponseError} from "../../../../../../../utils/errorHandlers/handleResponseError";
import routes from "../../../../../../../utils/defaults/routes";
import {useNavigate} from "react-router-dom";
import useSheet from "../../useSheet";
import {SpinnerIcon} from "../../../../../../../media/icons/custom/spinnerIcon";
import {cn} from "../../../../../../../utils/cn/cnHelper";
import {Popover, PopoverContent, PopoverTrigger} from "../../../../../../../components/popOver";
import {PopoverClose} from "@radix-ui/react-popover";

interface Props {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

const AssignDeliveryDate = ({order, setOrder}: Props) => {
    const {close} = useSheet()
    const [isLoading, setLoading] = useState<boolean>(false);
    const [currentDeliveryDate, setCurrentDeliveryDate] = useState<Date | undefined>(undefined);
    const [currentDeliveryTime, setCurrentDeliveryTime] = useState<AppointmentTime | null>(null);
    const navigate = useNavigate()
    const [isValid, setIsValid] = useState<{
        currentDeliveryDate: boolean, currentDeliveryTime: boolean
    }>({currentDeliveryDate: true, currentDeliveryTime: true});

    const dayModifier = () => {
        if (currentDeliveryDate) {
            const res = {
                left: [dayjs(currentDeliveryDate).subtract(1, "day").toDate(),],
                right: [dayjs(currentDeliveryDate).add(1, "day").toDate()],
            }
            return res as DayModifiers
        } else return undefined
    }

    const getTimeToString = (appointmentTime: AppointmentTime | null) => {
        if (!appointmentTime) return 'Time'

        switch (appointmentTime) {
            case AppointmentTime.morning: {
                return 'Morning (8-11 AM)'
            }

            case AppointmentTime.afternoon: {
                return 'Afternoon (12-3 PM)'
            }

            case AppointmentTime.evening: {
                return 'Evening (4-6 PM)'
            }

            default: {
                return 'Time'
            }
        }
    }

    const getDateToString = (date: Date | string | undefined, isSpecific: boolean) => {
        if (date === undefined) return 'Date';
        else {
            if (!isSpecific) {
                let dateString: string = ``

                const previousDate = dayjs(date).subtract(1, 'day')
                const nextDate = dayjs(date).add(1, 'day')

                if (previousDate.month() === nextDate.month()) {
                    dateString = previousDate.format('MMM DD') + '-' + nextDate.format('DD')
                } else dateString = previousDate.format('MMM DD') + ' - ' + nextDate.format('MMM DD')

                return dateString
            } else return `${dayjs(date).format('MMM DD')}`;

        }
    }

    const onSave = async () => {
        if (order.deliverySpecificDate) return
        else {

            if (!currentDeliveryDate) {
                setIsValid(prevState => {
                    return {
                        ...prevState,
                        currentDeliveryDate: false,
                    }
                })
            }

            if (!currentDeliveryTime) {
                setIsValid(prevState => {
                    return {
                        ...prevState,
                        currentDeliveryTime: false,
                    }
                })
            }

            if (!currentDeliveryDate || !currentDeliveryTime) return

            else {
                setLoading(true)
                const res = await postDeliveryDate(order.id, currentDeliveryDate, currentDeliveryTime)
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    setLoading(false)
                    handleResponseError(res, () => navigate(routes.login))
                } else {
                    setOrder(res)
                    setLoading(false)
                    close()
                }
            }
        }
    }

    useEffect(() => {
        if (order) {
            const orderDeliveryDate = order.deliveryDate
            const orderDeliveryTime = order.deliveryTime

            if (orderDeliveryDate) setCurrentDeliveryDate(dayjs(orderDeliveryDate).toDate())
            if (orderDeliveryTime) setCurrentDeliveryTime(orderDeliveryTime)
        }
    }, [order]);

    useEffect(() => {
        setIsValid({currentDeliveryDate: true, currentDeliveryTime: true})
    }, [currentDeliveryDate, currentDeliveryTime]);


    return <div className={'h-[calc(100dvh-4.25rem)] flex flex-col gap-4'}>


        <section className={'flex-1 flex flex-col gap-6 px-6 pt-6 relative'}>

            <div className={'flex flex-col gap-4'}>

                <div className={'flex flex-col gap-2'}>
                    <p className={'text-[18px] leading-[24px] font-[500]'}>Pickup</p>
                    <p className={'text-[16px] leading-[24px] font-[400] text-[#717171]'}>The specific date or date
                        range chosen by the customer. </p>
                </div>


                <div className={'flex flex-row items-center gap-4'}>

                    <section className={'flex-1 flex flex-col gap-1'}>
                        <p className={'text-[14px] leading-[20px] font-[500]'}>Date</p>
                        <button
                            type={'button'}
                            disabled={true}
                            className={`opacity-80 flex flex-row justify-between items-center text-[16px] h-[48px] rounded-[6px] gap-[10px] pl-4 pr-3 ring-[#DDDDDD] ring-1`}>
                            <p className={`opacity-80 text-[16px] leading-[24px] font-[400] text-[#222222]`}>{order.pickupDate ? getDateToString(order?.pickupDate, false) : ''}</p>

                            <svg className={'opacity-80'} width="12" height="8"
                                 viewBox="0 0 12 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.5L6 6.5L11 1.5"
                                      stroke={'#222222'}
                                      strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </section>


                    <section className={'flex-1 flex flex-col gap-1'}>
                        <p className={'text-[14px] leading-[20px] font-[500]'}>Time</p>
                        <button
                            type={'button'}
                            disabled={true}
                            className={`opacity-80 flex flex-row justify-between items-center text-[16px] h-[48px] rounded-[6px] gap-[10px] pl-4 pr-3 ring-[#DDDDDD] ring-1`}>
                            <p className={`opacity-80 text-[16px] leading-[24px] font-[400] text-[#222222]`}>{getTimeToString(order.appointmentTime)}</p>

                            <svg className={'opacity-80'} width="12" height="8"
                                 viewBox="0 0 12 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.5L6 6.5L11 1.5"
                                      stroke={'#222222'}
                                      strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </section>


                </div>
            </div>


            <div className={'flex flex-col gap-4'}>
                <div className={'flex flex-col gap-2'}>
                    <p className={'text-[18px] leading-[24px] font-[500]'}>Delivery</p>
                    <p className={'text-[16px] leading-[24px] font-[400] text-[#717171]'}>The specific date or date
                        range chosen by the customer. If any changes are needed, please contact us. </p>
                </div>

                <div className={'flex flex-row items-center gap-4'}>

                    <section className={'flex-1 flex flex-col gap-1'}>
                        <p className={'text-[14px] leading-[20px] font-[500]'}>Date</p>

                        <Popover>
                            <PopoverTrigger asChild>
                                <button
                                    type={'button'}
                                    className={` flex flex-row justify-between items-center text-[16px] h-[48px] rounded-[6px] gap-[10px] pl-4 pr-3 ${!isValid.currentDeliveryDate ? 'ring-[#C6241D] ring-2' : 'ring-[#DDDDDD] ring-1'}`}>
                                    <p className={`text-[16px] leading-[24px] font-[400] ${!isValid.currentDeliveryDate ? 'text-[#C6241D]' : 'text-[#222222]'}`}>{getDateToString(currentDeliveryDate, false)}</p>

                                    <svg width="12" height="8"
                                         viewBox="0 0 12 8" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1.5L6 6.5L11 1.5"
                                              stroke={!isValid.currentDeliveryDate ? '#C6241D' : '#222222'}
                                              strokeWidth="1.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </PopoverTrigger>

                            <PopoverContent align={'start'}
                                            className="w-fit h-fit p-6 flex flex-col gap-4 rounded-md"
                                            sticky={'always'}>

                                <DayPicker
                                    classNames={{
                                        caption:
                                            "h-[20px] flex justify-center relative items-center text-center",
                                        caption_label:
                                            "text-marco_default_foreground text-[16] leading-[20px] font-[600]",
                                        month: "space-y-2",
                                        months:
                                            "flex-1 flex flex-row items-center justify-between",
                                        nav: "space-x-1 flex items-center",
                                        nav_button: cn(
                                            buttonVariants({variant: "icon"}),
                                            "bg-transparent transition-all duration-200 rounded-full p-0 w-8 h-8 rounded-full hover:bg-[#EBEBEB]",
                                        ),
                                        nav_button_next: "absolute right-0 ",
                                        nav_button_previous: "absolute left-0",
                                        table: "w-[348px] border-collapse ",
                                        // days of week
                                        cell: "h-[48px] w-[48px] text-[14px] leading-[20px] font-600 p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md  first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                        day: cn(buttonVariants({variant: "ghost"}), `disabled:opacity-100 rounded-full h-[48px] w-[48px] p-0 font-normal`),
                                        day_range_end: "day-range-end",
                                        day_selected:
                                            "bg-slate-900 !text-slate-50 hover:bg-slate-900 hover:text-slate-50 focus:bg-slate-900 focus:text-slate-50 ",
                                        day_today: `border-2 border-[#cccccc] !text-neutral-800`,
                                        head_cell:
                                            "w-[48px] h-[48px] text-[12px] leading-[16px] font-[600] text-[#717171] leading-tight pt-2 content-center",
                                        head_row: "flex flex-row justify-between",
                                        row: "flex flex-row w-full justify-between pt-[2px]",
                                        // days => new month
                                        day_outside: "text-slate-600",
                                        // days passed
                                        day_disabled: `!text-[#cccccc] !bg-white`,
                                        day_hidden: "invisible",
                                        day_range_middle: "",
                                    }}
                                    modifiers={dayModifier()}
                                    modifiersClassNames={{
                                        left: "rounded-full bg-[#EBEBEB]",
                                        right: "rounded-full bg-[#EBEBEB]",
                                    }}
                                    components={{
                                        IconLeft: () => (
                                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M6.78865 0.180309C7.05725 0.432135 7.07086 0.854016 6.81905 1.12263L2.2465 6L6.81905 10.8773C7.07086 11.146 7.05725 11.5679 6.78865 11.8197C6.52003 12.0715 6.09814 12.0579 5.84633 11.7893L0.846326 6.45596C0.605912 6.19952 0.605912 5.80048 0.846326 5.54404L5.84633 0.210709C6.09814 -0.0579047 6.52003 -0.0715047 6.78865 0.180309Z"
                                                      fill="#222222"/>
                                            </svg>
                                        ),
                                        IconRight: () => (
                                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M0.210713 0.180314C0.479313 -0.0715126 0.901205 -0.0578994 1.15303 0.210713L6.15297 5.54404C6.39337 5.80046 6.39337 6.19951 6.15297 6.45595L1.15303 11.7893C0.901205 12.0579 0.479313 12.0715 0.210713 11.8197C-0.0578994 11.5678 -0.0715126 11.1459 0.180314 10.8774L4.75284 6L0.180314 1.12263C-0.0715126 0.854019 -0.0578994 0.432126 0.210713 0.180314Z"
                                                      fill="#222222"/>
                                            </svg>

                                        ),
                                    }}
                                    defaultMonth={currentDeliveryDate ? dayjs(currentDeliveryDate).toDate() : dayjs().toDate()}
                                    disabled={(date) =>
                                        date < dayjs().toDate() || date < dayjs(order.pickupDate).add(2, 'day').toDate() || date > dayjs(order.pickupDate).add(31, 'day').toDate() || date < new Date("1900-01-01")
                                    }
                                    fixedWeeks={false}
                                    mode="single"
                                    onSelect={(e) => {
                                        setCurrentDeliveryDate(e)
                                    }}
                                    selected={currentDeliveryDate}
                                    showOutsideDays
                                />
                            </PopoverContent>
                        </Popover>
                    </section>


                    <section className={'flex-1 flex flex-col gap-1'}>
                        <p className={'text-[14px] leading-[20px] font-[500]'}>Time</p>
                        <Popover>
                            <PopoverTrigger asChild>
                                <button
                                    type={'button'}
                                    className={`flex flex-row justify-between items-center text-[16px] h-[48px] rounded-[6px] gap-[10px] pl-4 pr-3 ${!isValid.currentDeliveryTime ? 'ring-[#C6241D] ring-2' : 'ring-[#DDDDDD] ring-1'}`}>
                                    <p className={` text-[16px] leading-[24px] font-[400] ${!isValid.currentDeliveryTime ? 'text-[#C6241D]' : 'text-[#222222]'}`}>{getTimeToString(currentDeliveryTime)}</p>
                                    <svg width="12" height="8"
                                         viewBox="0 0 12 8" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1.5L6 6.5L11 1.5"
                                              stroke={!isValid.currentDeliveryTime ? '#C6241D' : '#222222'}
                                              strokeWidth="1.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </PopoverTrigger>

                            <PopoverContent align={'center'} className="w-full h-fit p-2 flex flex-col gap-0 rounded-md"
                                            sticky={'always'}>

                                <PopoverClose data-testid={'delivery-morning'}
                                              onClick={() => setCurrentDeliveryTime(AppointmentTime.morning)}
                                              className={'rounded-[6px] cursor-pointer text-start p-0 m-0 h-[40px] flex flex-row items-center justify-between gap-4 hover:bg-[#F7F7F7]'}>
                                    <p className={'flex-1 pl-3 text-[14px] leading-[20px] font-normal'}>
                                        Morning (8-11 AM)
                                    </p>

                                    <svg
                                        className={`mr-4 ${currentDeliveryTime === AppointmentTime.morning ? 'visible' : 'invisible'}`}
                                        width="12" height="9" viewBox="0 0 12 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3346 1L4.0013 8.33333L0.667969 5" stroke="#222222"
                                              strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </PopoverClose>

                                <PopoverClose data-testid={'delivery-afternoon'}
                                              onClick={() => setCurrentDeliveryTime(AppointmentTime.afternoon)}
                                              className={'rounded-[6px] cursor-pointer text-start p-0 m-0 h-[40px] flex flex-row items-center justify-between gap-4 hover:bg-[#F7F7F7]'}>
                                    <p className={'flex-1 pl-3 text-[14px] leading-[20px] font-normal'}>
                                        Afternoon (12-3 PM)
                                    </p>

                                    <svg
                                        className={`mr-4 ${currentDeliveryTime === AppointmentTime.afternoon ? 'visible' : 'invisible'}`}
                                        width="12" height="9" viewBox="0 0 12 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3346 1L4.0013 8.33333L0.667969 5" stroke="#222222"
                                              strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </PopoverClose>

                                <PopoverClose data-testid={'delivery-evening'}
                                              onClick={() => setCurrentDeliveryTime(AppointmentTime.evening)}
                                              className={'rounded-[6px] cursor-pointer text-start p-0 m-0 h-[40px] flex flex-row items-center justify-between gap-4 hover:bg-[#F7F7F7]'}>
                                    <p className={'flex-1 pl-3 text-[14px] leading-[20px] font-normal'}>
                                        Evening (4-6 PM)
                                    </p>

                                    <svg
                                        className={`mr-4 ${currentDeliveryTime === AppointmentTime.evening ? 'visible' : 'invisible'}`}
                                        width="12" height="9" viewBox="0 0 12 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3346 1L4.0013 8.33333L0.667969 5" stroke="#222222"
                                              strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </PopoverClose>
                            </PopoverContent>
                        </Popover>
                    </section>
                </div>
            </div>
        </section>


        <section className={`flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4`}>
            <Button size={'lg'}
                    data-testid={'on-cancel-handle'}
                    disabled={isLoading}
                    type={'button'}
                    variant={'outline'}
                    className={'w-full px-5 text-[#222222] text-center text-[16px] leading-[20px] font-medium'}
                    onClick={close}>Cancel</Button>
            <Button
                size={'lg'}
                variant={'default'}
                type={'button'}
                disabled={isLoading}
                onClick={onSave}
                data-testid={'on-submit-handle'}
                className={'w-full text-base font-medium leading-tight relative'}>
                <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                             size={20}/>
                <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
            </Button>
        </section>
    </div>
}

export default AssignDeliveryDate