import {create} from 'zustand'
import {Address} from "../types/structures/Address";

import getDirectionsNoMap from "../getDirectionsNoMap";

interface State {
    currentOriginAddress: Address | null
    currentDestinationAddress: Address | null
    originCoordinates: google.maps.LatLngLiteral | null
    destinationCoordinates: google.maps.LatLngLiteral | null
    directionsResult: google.maps.DirectionsResult | null
    directionsRenderer: google.maps.DirectionsRenderer
}

interface Actions {
    initDirections: (originAddress: Address | null | undefined, destinationAddress: Address | null | undefined) => Promise<{
        originCoordinates: google.maps.LatLngLiteral | null,
        destinationCoordinates: google.maps.LatLngLiteral | null,
        directionsResult: google.maps.DirectionsResult,
    } | null>
}

const useDirections = create<State & Actions>(
    (set, get) => ({
        originCoordinates: null,
        destinationCoordinates: null,
        directionsResult: null,
        currentOriginAddress: null,
        currentDestinationAddress: null,
        directionsRenderer: new google.maps.DirectionsRenderer({suppressMarkers: true}),

        initDirections: async (originAddress: Address | null | undefined, destinationAddress: Address | null | undefined) => {

            if (originAddress?.address && destinationAddress?.address) {

                const currentOriginAddress = get().currentOriginAddress
                const currentDestinationAddress = get().currentDestinationAddress


                if (originAddress?.address && destinationAddress?.address) {
                    if (currentOriginAddress?.address !== originAddress.address || currentDestinationAddress?.address !== destinationAddress.address) {
                        const directions = await getDirectionsNoMap(originAddress.address, destinationAddress.address)

                        if (directions) {
                            set(
                                (state) => (
                                    {
                                        originCoordinates: directions.originCoordinates,
                                        destinationCoordinates: directions.destinationCoordinates,
                                        directionsResult: directions.directionsResult,
                                        currentOriginAddress: originAddress,
                                        currentDestinationAddress: destinationAddress,
                                    }
                                )
                            )

                            return {
                                originCoordinates: directions.originCoordinates,
                                destinationCoordinates: directions.destinationCoordinates,
                                directionsResult: directions.directionsResult,
                            }
                        } else {
                            set(
                                (state) => (
                                    {
                                        originCoordinates: null,
                                        destinationCoordinates: null,
                                        directionsResult: null,
                                    }
                                )
                            )
                        }

                    }
                }
            }

            return null
        },
    })
)

export default useDirections;
