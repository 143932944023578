import * as React from "react";
import {Accordion} from "src/components/accordion";
import CarrierImages from "src/pages/dashboarSection/orderDetails/sections/attachments/components/carrierImages";
import UnEditableOrderImages
    from "src/pages/dashboarSection/orderDetails/sections/attachments/components/unEditableOrderImages";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import {OrderDetails} from "../../../../../utils/types/structures/orderDetails";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
order: OrderDetails;
}

export default function AttachmentsContent({...props}: Props) {
    const {systemImages, customerImages, adminImages} = useOrderAttachments()
    return <div {...props}>
        <Accordion type="multiple" defaultValue={['carrier', 'customer']}>
            <UnEditableOrderImages title={'Customer'} orderImages={customerImages}/>
            <CarrierImages order={props.order}/>
            {systemImages.length > 0 && <UnEditableOrderImages title={'Platform'} orderImages={systemImages}/>}
            {adminImages.length > 0 && <UnEditableOrderImages title={'Admin'} orderImages={adminImages}/>}
        </Accordion>
    </div>
}