import type {UseFormReturn} from "react-hook-form";
import React from "react";
import {FormControl, FormField, FormItem} from "src/components/form";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";

interface Props {
    form: UseFormReturn<{
        access: string;
        floorNumber: string;
        googleMapAddress: string;
        manualApt?: string;
        manualCity: string;
        manualCountry: string;
        manualState: string;
        manualStreetAddress: string;
        manualZip: string;

        numberStories: string;
        propertySize: string;
        propertyType: string;
        squareFootage: string;
        unitNumber: string;
        unitSize: string;
    }>;
}

export default function ApartmentFloor({form}: Props) {
    return (
        <section className={'flex flex-col gap-2'}>

            <p className={`text-[14px] leading-[16px] font-medium ${
                form.getFieldState("floorNumber").invalid
                    ? "text-[#C6241D] outline-[#C6241D] outline-2"
                    : ""
            }`}>Floor</p>

            <FormField
                control={form.control}
                name="floorNumber"
                render={({field}) => (
                    <FormItem className="flex-1">
                        <Select

                            onValueChange={(e) => {
                                form.setValue("floorNumber", e);
                                form.clearErrors("floorNumber");
                            }}
                            value={field.value}
                        >
                            <FormControl>
                                <SelectTrigger data-testid={'commercial-floor-number'}
                                    className={`${form.getFieldState("floorNumber").invalid ? "text-[#C6241D] outline-[#C6241D] outline-2" : ""}`}
                                >
                                    <div
                                        className={`${
                                            form.getValues("floorNumber") === ""
                                                ? "text-[#717171] pl-1"
                                                : "pl-1 text-marco_default_foreground"
                                        }
                  ${form.getFieldState("floorNumber").invalid ? "!text-[#C6241D]" : ""}
                  text-[16px] leading-[24px] font-normal`}
                                    >
                                        <SelectValue placeholder="Floor"/>
                                    </div>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                <SelectItem key={"Basement"} value={"Basement"}>
                                    Basement
                                </SelectItem>

                                <SelectItem key={"GroundFloor"} value={"GroundFloor"}>
                                    Ground Floor
                                </SelectItem>

                                <SelectItem key={"FIRST_FLOOR"} value={"FIRST_FLOOR"}>
                                    1st Floor
                                </SelectItem>

                                <SelectItem key={"SECOND_FLOOR"} value={"SECOND_FLOOR"}>
                                    2nd Floor
                                </SelectItem>

                                <SelectItem key={"THIRD_FLOOR"} value={"THIRD_FLOOR"}>
                                    3rd Floor
                                </SelectItem>

                                <SelectItem key={"FOURTH_FLOOR"} value={"FOURTH_FLOOR"}>
                                    4th Floor
                                </SelectItem>

                                <SelectItem key={"FIFTH_FLOOR"} value={"FIFTH_FLOOR"}>
                                    5th Floor
                                </SelectItem>

                                <SelectItem key={"SIXTH_FLOOR"} value={"SIXTH_FLOOR"}>
                                    6th Floor
                                </SelectItem>

                                <SelectItem key={"SEVENTH_FLOOR"} value={"SEVENTH_FLOOR"}>
                                    7th Floor
                                </SelectItem>

                                <SelectItem key={"EIGHTH_FLOOR"} value={"EIGHTH_FLOOR"}>
                                    8th Floor
                                </SelectItem>

                                <SelectItem key={"NINTH_FLOOR"} value={"NINTH_FLOOR"}>
                                    9th Floor
                                </SelectItem>

                                <SelectItem key={"TENTH_FLOOR"} value={"TENTH_FLOOR"}>
                                    10th Floor
                                </SelectItem>

                                <SelectItem key={"ELEVENTH_FLOOR"} value={"ELEVENTH_FLOOR"}>
                                    11th Floor
                                </SelectItem>

                                <SelectItem key={"TWELVE_FLOOR"} value={"TWELVE_FLOOR"}>
                                    12th Floor
                                </SelectItem>

                                <SelectItem key={"THIRTEENTH_FLOOR"} value={"THIRTEENTH_FLOOR"}>
                                    13th Floor
                                </SelectItem>

                                <SelectItem key={"FOURTEENTH_FLOOR"} value={"FOURTEENTH_FLOOR"}>
                                    14th Floor
                                </SelectItem>

                                <SelectItem key={"FIFTEENTH_FLOOR"} value={"FIFTEENTH_FLOOR"}>
                                    15th Floor
                                </SelectItem>

                                <SelectItem key={"SIXTEENTH_FLOOR"} value={"SIXTEENTH_FLOOR"}>
                                    16th Floor
                                </SelectItem>

                                <SelectItem key={"SEVENTEENTH_FLOOR"} value={"SEVENTEENTH_FLOOR"}>
                                    17th Floor
                                </SelectItem>

                                <SelectItem key={"EIGHTEENTH_FLOOR"} value={"EIGHTEENTH_FLOOR"}>
                                    18th Floor
                                </SelectItem>

                                <SelectItem key={"NINETEENTH_FLOOR"} value={"NINETEENTH_FLOOR"}>
                                    19th Floor
                                </SelectItem>

                                <SelectItem key={"TWENTIETH_FLOOR"} value={"TWENTIETH_FLOOR"}>
                                    20th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_FIRST_FLOOR"}
                                    value={"TWENTY_FIRST_FLOOR"}
                                >
                                    21st Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_SECOND_FLOOR"}
                                    value={"TWENTY_SECOND_FLOOR"}
                                >
                                    22nd Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_THIRD_FLOOR"}
                                    value={"TWENTY_THIRD_FLOOR"}
                                >
                                    23rd Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_FOURTH_FLOOR"}
                                    value={"TWENTY_FOURTH_FLOOR"}
                                >
                                    24th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_FIFTH_FLOOR"}
                                    value={"TWENTY_FIFTH_FLOOR"}
                                >
                                    25th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_SIXTH_FLOOR"}
                                    value={"TWENTY_SIXTH_FLOOR"}
                                >
                                    26th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_SEVENTH_FLOOR"}
                                    value={"TWENTY_SEVENTH_FLOOR"}
                                >
                                    27th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_EIGHTH_FLOOR"}
                                    value={"TWENTY_EIGHTH_FLOOR"}
                                >
                                    28th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_NINTH_FLOOR"}
                                    value={"TWENTY_NINTH_FLOOR"}
                                >
                                    29th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"TWENTY_THIRTY_FLOOR"}
                                    value={"TWENTY_THIRTY_FLOOR"}
                                >
                                    30th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_FIRST_FLOOR"}
                                    value={"THIRTY_FIRST_FLOOR"}
                                >
                                    31st Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_SECOND_FLOOR"}
                                    value={"THIRTY_SECOND_FLOOR"}
                                >
                                    32nd Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_THIRD_FLOOR"}
                                    value={"THIRTY_THIRD_FLOOR"}
                                >
                                    33rd Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_FOURTH_FLOOR"}
                                    value={"THIRTY_FOURTH_FLOOR"}
                                >
                                    34th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_FIFTH_FLOOR"}
                                    value={"THIRTY_FIFTH_FLOOR"}
                                >
                                    35th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_SIXTH_FLOOR"}
                                    value={"THIRTY_SIXTH_FLOOR"}
                                >
                                    36th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_SEVENTH_FLOOR"}
                                    value={"THIRTY_SEVENTH_FLOOR"}
                                >
                                    37th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_EIGHTH_FLOOR"}
                                    value={"THIRTY_EIGHTH_FLOOR"}
                                >
                                    38th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"THIRTY_NINTH_FLOOR"}
                                    value={"THIRTY_NINTH_FLOOR"}
                                >
                                    39th Floor
                                </SelectItem>

                                <SelectItem key={"FORTY_FLOOR"} value={"FORTY_FLOOR"}>
                                    40th Floor
                                </SelectItem>

                                <SelectItem key={"FORTY_FIRST_FLOOR"} value={"FORTY_FIRST_FLOOR"}>
                                    41st Floor
                                </SelectItem>

                                <SelectItem
                                    key={"FORTY_SECOND_FLOOR"}
                                    value={"FORTY_SECOND_FLOOR"}
                                >
                                    42nd Floor
                                </SelectItem>

                                <SelectItem key={"FORTY_THIRD_FLOOR"} value={"FORTY_THIRD_FLOOR"}>
                                    43rd Floor
                                </SelectItem>

                                <SelectItem
                                    key={"FORTY_FOURTH_FLOOR"}
                                    value={"FORTY_FOURTH_FLOOR"}
                                >
                                    44th Floor
                                </SelectItem>

                                <SelectItem key={"FORTY_FIFTH_FLOOR"} value={"FORTY_FIFTH_FLOOR"}>
                                    45th Floor
                                </SelectItem>

                                <SelectItem key={"FORTY_SIXTH_FLOOR"} value={"FORTY_SIXTH_FLOOR"}>
                                    46th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"FORTY_SEVENTH_FLOOR"}
                                    value={"FORTY_SEVENTH_FLOOR"}
                                >
                                    47th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"FORTY_EIGHTH_FLOOR"}
                                    value={"FORTY_EIGHTH_FLOOR"}
                                >
                                    48th Floor
                                </SelectItem>

                                <SelectItem key={"FORTY_NINTH_FLOOR"} value={"FORTY_NINTH_FLOOR"}>
                                    49th Floor
                                </SelectItem>

                                <SelectItem key={"FIFTY_FLOOR"} value={"FIFTY_FLOOR"}>
                                    50th Floor
                                </SelectItem>

                                <SelectItem key={"FIFTY_FIRST_FLOOR"} value={"FIFTY_FIRST_FLOOR"}>
                                    51st Floor
                                </SelectItem>

                                <SelectItem
                                    key={"FIFTY_SECOND_FLOOR"}
                                    value={"FIFTY_SECOND_FLOOR"}
                                >
                                    52nd Floor
                                </SelectItem>

                                <SelectItem key={"FIFTY_THIRD_FLOOR"} value={"FIFTY_THIRD_FLOOR"}>
                                    53rd Floor
                                </SelectItem>

                                <SelectItem
                                    key={"FIFTY_FOURTH_FLOOR"}
                                    value={"FIFTY_FOURTH_FLOOR"}
                                >
                                    54th Floor
                                </SelectItem>

                                <SelectItem key={"FIFTY_FIFTH_FLOOR"} value={"FIFTY_FIFTH_FLOOR"}>
                                    55th Floor
                                </SelectItem>

                                <SelectItem key={"FIFTY_SIXTH_FLOOR"} value={"FIFTY_SIXTH_FLOOR"}>
                                    56th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"FIFTY_SEVENTH_FLOOR"}
                                    value={"FIFTY_SEVENTH_FLOOR"}
                                >
                                    57th Floor
                                </SelectItem>

                                <SelectItem
                                    key={"FIFTY_EIGHTH_FLOOR"}
                                    value={"FIFTY_EIGHTH_FLOOR"}
                                >
                                    58th Floor
                                </SelectItem>

                                <SelectItem key={"FIFTY_NINTH_FLOOR"} value={"FIFTY_NINTH_FLOOR"}>
                                    59th Floor
                                </SelectItem>

                                <SelectItem key={"SIXTY_FLOOR"} value={"SIXTY_FLOOR"}>
                                    60th Floor
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    </FormItem>
                )}
            />
        </section>

    );
}
