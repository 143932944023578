import DirectionsRequest = google.maps.DirectionsRequest;
import TravelMode = google.maps.TravelMode;

export default async function getDirectionsNoMap(originAddress: string, destinationAddress: string) {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});

    const request: DirectionsRequest = {
        origin: originAddress,
        destination: destinationAddress,
        travelMode: TravelMode.DRIVING
    };

    const directionsResult = await directionsService.route(request,
        function (response, status) {
            if (response && status === 'OK') {
                directionsRenderer.setOptions({
                    polylineOptions:
                        {
                            strokeColor: '#00442E',
                            strokeOpacity: 0.8,
                            strokeWeight: 2
                        }
                })
                return response
            }
        })
        .then(value => {return value})

    if (directionsResult && directionsResult.routes[0]) {

        const originCoordinates = directionsResult.routes[0].overview_path[0].toJSON()
        const destinationCoordinates = directionsResult.routes[0].overview_path[directionsResult.routes[0].overview_path.length-1].toJSON()

        return {
            originCoordinates: originCoordinates,
            destinationCoordinates: destinationCoordinates,
            directionsResult: directionsResult
        }
    }
}