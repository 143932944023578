import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import useTokenStore from "../../zustandStores/primary/useTokenStore";
import {ErrorMessage} from "src/utils/types/errorMessage";
import otherError from "../../errorHandlers/otherError";

export async function createOriginMetadata(itemId: string, metadata: string | undefined | null, type: "orderItem" | "customItem") {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    const notes = typeof metadata === "string" ? metadata : ""

    if (error) return {data: null, error: otherError}
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/order/${type === 'orderItem' ? 'items' : 'customItems'}/originMetadata`), {
            method: 'POST',
            body: JSON.stringify({
                id: itemId,
                metadata: {
                    NOTE: [notes]
                }
            }),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: string = await res.json();
            return {data: data, error: null}
        } else {
            const err = await getDefinedError(res)
            return {data: null, error: err}
        }

    } catch (e) {
        const err: ErrorMessage = {
            status: '400',
            message: 'unhandled exception'
        }
        return {data: null, error: err}
    }
}