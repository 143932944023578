import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import LoadingStatus from "src/components/loadingStatus";
import {toast} from "src/components/useToast";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {getOrder} from "src/utils/apiCalls/orderDetails/getOrder";
import {arrivedAtDelivery} from "src/utils/apiCalls/orderFulfillmentFlow/arrivedDelivery";
import {arrivedAtPickup} from "src/utils/apiCalls/orderFulfillmentFlow/arrivedAtPickup";
import {completeDelivery} from "src/utils/apiCalls/orderFulfillmentFlow/completeDelivery";
import {completePickup} from "src/utils/apiCalls/orderFulfillmentFlow/completePickup";
import {completeSurvey} from "src/utils/apiCalls/orderFulfillmentFlow/completeSurvey";
import {dispatchOrder} from "src/utils/apiCalls/orderFulfillmentFlow/dispatchOrder";
import routes from "src/utils/defaults/routes";
import {OrderFulfillmentStatus} from "src/utils/enums/orderFulfillmentStatus";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderDetails, PaymentStatus} from "src/utils/types/structures/orderDetails";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails | undefined
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
    isLoading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DetailsButtonSet({order, setOrder, isLoading, setLoading, ...props}: Props) {
    const navigate = useNavigate();
    const {open} = useDialog()

    useEffect(() => {
        if (order) {
            if (order.paymentStatus === PaymentStatus.paymentDeclined ||
                order.fulfillmentStatus === OrderFulfillmentStatus.awaitingSignatureAtPickup ||
                order.fulfillmentStatus === OrderFulfillmentStatus.awaitingSignatureAtDelivery ||
                order.fulfillmentStatus === OrderFulfillmentStatus.awaitingPaymentAtDelivery ||
                order.fulfillmentStatus === OrderFulfillmentStatus.awaitingSignatureForConfirmation ||
                order.fulfillmentStatus === OrderFulfillmentStatus.awaitingPaymentAtPickup) {
                const interval = setInterval(async () => {
                    const res = await getOrder(order.id)
                    if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate('/'))
                    else if (res.fulfillmentStatus !== order.fulfillmentStatus || res.paymentStatus !== order.paymentStatus) {
                        setOrder(res)
                    }
                }, 15000)
                return () => clearInterval(interval)
            }
        }
    }, [order]);

    return <div {...props}>
        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.accepted &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-dispatch-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver dispatched to origin
                    if (order) {
                        if (!order.driver) toast({variant: 'destructive', description: 'Driver not assigned'})
                        else {
                            setLoading(true)
                            const res = await dispatchOrder(order.id)
                            if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                            else setOrder(res);
                            setLoading(false)
                        }
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Dispatch'}
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.dispatched &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-arrive-at-pickup-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver arrived at origin, backend: sms to customer
                    if (order) {
                        setLoading(true)
                        const res = await arrivedAtPickup(order.id)
                        if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                        else setOrder(res)
                        setLoading(false)
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Arrived at Pickup'}
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.arrivedAtPickup &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-start-survey-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver enters pin-code received from customer
                    if (order) {
                        open(DialogElementType.PINForm, order, 'Let’s get work started', async () => {
                            setLoading(true)
                            const res = await getOrder(order.id)
                            if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                            else setOrder(res);
                            setLoading(false)
                        })
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Start Survey'}
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.surveyAtPickup &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-complete-survey-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver completes survey, backend: generate contract at pickup
                    if (order) {
                        if (!order.deliveryDate || !order.deliveryTime) {
                            toast({
                                variant: 'destructive',
                                description: 'Assign delivery date and time from "More actions" menu'
                            })
                        } else {
                            setLoading(true)
                            const res = await completeSurvey(order.id)
                            if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                            else setOrder(res);
                            setLoading(false)
                        }
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Complete survey'}
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.awaitingSignatureAtPickup &&
            <Button disabled={true}
                    className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}>
                <LoadingStatus/>
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.awaitingPaymentAtPickup &&
            <Button disabled={true}
                    className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}>
                <LoadingStatus/>
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.pickupStarted &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-complete-pickup-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver completed pickup and dispatched to destination
                    if (order) {
                        setLoading(true)
                        const res = await completePickup(order.id)
                        if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                        else setOrder(res)
                        setLoading(false)
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Complete Pickup'}
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.inTransit &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-arrive-at-delivery-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver arrived at delivery, backend: sms to customer
                    if (order) {
                        setLoading(true)
                        const res = await arrivedAtDelivery(order.id)
                        if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                        else setOrder(res)
                        setLoading(false)
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Arrived at Delivery'}
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.arrivedAtDelivery &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-start-survey-at-destination-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver entered pin-code received from customer
                    if (order) {
                        open(DialogElementType.PINForm, order, 'Let’s get work started', async () => {
                            setLoading(true)
                            const res = await getOrder(order.id)
                            if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                            else setOrder(res);
                            setLoading(false)
                        })
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Start Survey'}
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.surveyAtDelivery &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-complete-survey-at-destination-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver completed survey at destination
                    if (order) {
                        setLoading(true)
                        const res = await completeSurvey(order.id)
                        if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                        else setOrder(res);
                        setLoading(false)
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Complete Survey'}
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.awaitingSignatureAtDelivery &&
            <Button disabled={true}
                    className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}>
                <LoadingStatus/>
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.awaitingPaymentAtDelivery &&
            <Button disabled={true}
                    className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}>
                <LoadingStatus/>
            </Button>
        }

        {
            order?.fulfillmentStatus === OrderFulfillmentStatus.deliveryStarted &&
            <Button
                disabled={isLoading || (order.paymentStatus && order.paymentStatus === PaymentStatus.paymentDeclined)}
                data-testid={'on-complete-delivery-handle'}
                className={`text-[16px] md:text-[14px] font-[500] leading-[20px] text-white h-[48px] md:h-[40px] w-full md:w-fit relative px-5`}
                onClick={async () => {
                    // driver completed delivery
                    if (order) {
                        setLoading(true)
                        const res = await completeDelivery(order.id)
                        if (isInstanceOf<ErrorMessage>(res, 'message')) handleResponseError(res, () => navigate(routes.login))
                        else setOrder(res);
                        setLoading(false)
                    }
                }}>
                {isLoading ? <LoadingStatus/> : 'Complete Delivery'}
            </Button>
        }

        {order?.fulfillmentStatus === OrderFulfillmentStatus.delivered && <></>}
        {order?.fulfillmentStatus === OrderFulfillmentStatus.cancelled && <></>}
        {order?.fulfillmentStatus === OrderFulfillmentStatus.forfeited && <></>}
        {order?.fulfillmentStatus === OrderFulfillmentStatus.paymentDispute && <></>}
        {order?.fulfillmentStatus === OrderFulfillmentStatus.onHold && <></>}
    </div>
}