import React from "react";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {Button} from "../../../../../../components/button";

interface Props {
    order: OrderDetails
}

export default function CustomerInfoSection({order}: Props) {
    const {open} = useDialog()

    const openDialog = () => {
        open(DialogElementType.contactDetails, order, 'Contact the Customer')
    }


    return <section key={'customer-info'} className={'flex flex-col gap-4 border-b py-6 md:pt-0'}>
        <h2 className={'text-[#222222] text-[18px] leading-[24px] font-semibold'}>Customer</h2>

        <div className={'flex flex-1 items-center gap-4'}>
            <div className={'flex-1 flex gap-2 items-center'}>
                <Avatar className={'flex-none'}>
                    <AvatarImage
                        src={undefined}/>
                    <AvatarFallback
                        className={'text-sm'}>{getFirstLetters(order.customerFirstName ?? '', order.customerLastName ?? '')}</AvatarFallback>
                </Avatar>

                <div className={'flex-1 text-base font-normal leading-6'}>
                    {getString([
                        {element: order.customerFirstName ?? '', tag: ElementTag.name},
                        {element: order.customerLastName ?? '', tag: ElementTag.name},
                    ])}
                </div>
            </div>

            <div className={'flex-none flex gap-4'}>
                <Button variant={'outline'} data-testid={'customer-on-phone-click'} onClick={openDialog}
                        className={'px-4 h-[40px] items-center text-[14px] leading-[20px] font-[500]'}>
                    Message
                </Button>

                <Button variant={'outline'} data-testid={'customer-on-chat-click'} onClick={openDialog}
                        className={'px-4 h-[40px] items-center text-[14px] leading-[20px] font-[500]'}>
                    Call
                </Button>
            </div>
        </div>
    </section>
}