import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ReactComponent as MarcoLogo} from 'src/media/marcoLogo.svg';
import UserNamePopOver from "src/pages/dashboarSection/layout/header/userNamePopover";
import {getAccountUserCredentials} from "src/utils/apiCalls/getAccountUserCredentials";
import {BarSide} from "src/utils/defaults/menuBar";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import useHeaderNavStore from "src/utils/zustandStores/primary/useHeaderNavStore";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useUserHeader from "src/utils/zustandStores/userHeader/useUserHeader";

type Props = {
    isAtBottom?: boolean
}
export default function Header({isAtBottom}: Props) {
    const navigate = useNavigate();
    const {menuBar} = useHeaderNavStore();
    const {token, user: tokenUser} = useTokenStore();
    const {user, setUser} = useUserHeader()

    const getUser = async () => {
        const res = await getAccountUserCredentials()
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate(routes.login))
        } else {
            setUser(res)
        }
    }

    useEffect(() => {
        if (token) getUser()
    }, [token]);

    return <div className={`w-full ${isAtBottom ? 'border-t' : 'border-b'} place-items-center hidden md:flex flex-row px-padding`}>
        {!isAtBottom && <div className={'flex-none'}><MarcoLogo/></div>}


        <section className={"flex-1 grow flex items-center h-[68px]"}>
            <ul className={`flex-1 flex flex-row items-center ${isAtBottom ? '' : 'place-content-center'} text-sm h-full font-medium gap-10`}>
                {tokenUser && menuBar
                    .filter(el => el.desktop && el.side === BarSide.left)
                    .filter(el => el.permission.includes(tokenUser.roles))
                    .map(el =>
                        <li
                            key={el.title}
                            data-testid={`a-desktop-${el.title}`}
                            className={`py-[23px] cursor-pointer ${el.isActive ? 'text-foreground border-b-[0.127rem] border-marcoDarkGreen' : 'text-muted-foreground'}`}
                            onClick={() => {
                                navigate(el.route)
                            }}><p
                            className={`flex place-items-center h-full text-sm font-medium ${el.isActive && 'mt-[1px]'}`}>{el.title}</p>
                        </li>
                    )}
            </ul>

            <div className={"flex-none flex gap-8 justify-end items-center h-full"}>
                <ul className={"text-sm h-full font-medium flex-1 flex gap-8"}>
                    {tokenUser && menuBar
                        .filter(el => el.desktop && el.side === BarSide.right)
                        .filter(el => el.permission.includes(tokenUser.roles))
                        .map(el =>
                            <li
                                key={el.title}
                                data-testid={`a-desktop-${el.title}`}
                                className={`cursor-pointer ${el.isActive ? 'text-foreground border-b-[0.127rem] border-[#00442E]' : 'text-muted-foreground'}`}
                                onClick={() => {
                                    navigate(el.route)
                                }}><p
                                className={`flex place-items-center h-full text-sm font-medium ${el.isActive && 'mt-[1px]'}`}>{user?.companyName}</p>
                            </li>
                        )}

                    {(tokenUser && tokenUser.roles === 'DRIVER') &&
                        <p className={'text-sm h-full font-medium flex items-center text-muted-foreground'}>{user?.companyName}</p>}
                </ul>
                <UserNamePopOver/>
            </div>
        </section>
    </div>
}