import type {ReactNode} from "react";
import React, {useEffect, useState} from "react";
import type {UseFormReturn} from "react-hook-form";
import {FormControl, FormField, FormItem} from "src/components/form";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";
import PropertySize from "./propertyFields/propertySize";
import SquareFootage from "./propertyFields/squareFootage";
import UnitSize from "./propertyFields/unitSize";
import ApartmentFloor from "./propertyFields/apartment/apartmentFloor";
import AppartmentAccess from "./propertyFields/apartment/appartmentAccess";
import StorageFloor from "./propertyFields/storage/storageFloor";
import StorageAccess from "./propertyFields/storage/storageAccess";
import CommercialFloorNumber from "./propertyFields/commercial/commercialFloorNumber";
import CommercialAccess from "./propertyFields/commercial/commercialAccess";
import NumberStories from "./propertyFields/house/numberStories";
import HouseAccess from "./propertyFields/house/houseAccess";
import {Address} from "../../../../../../../../../utils/types/structures/Address";
import PropertySizeDelivery from "./propertyFields/propertySize";
import ApartmentFloorDelivery from "./propertyFields/apartment/apartmentFloor";
import AppartmentAccessDelivery from "./propertyFields/apartment/appartmentAccess";
import NumberStoriesDelivery from "./propertyFields/house/numberStories";
import HouseAccessDelivery from "./propertyFields/house/houseAccess";
import UnitSizeDelivery from "./propertyFields/unitSize";
import StorageFloorDelivery from "./propertyFields/storage/storageFloor";
import StorageAccessDelivery from "./propertyFields/storage/storageAccess";
import SquareFootageDelivery from "./propertyFields/squareFootage";
import CommercialAccessDelivery from "./propertyFields/commercial/commercialAccess";

interface Props {
  address: Address | null;

  form: UseFormReturn<{
    access: string;
    floorNumber: string;
    numberStories: string;
    propertySize: string;
    propertyType: string;
    squareFootage: string;
    unitNumber: string;
    unitSize: string;
  }>;
  isDashboard: boolean;
}

export function PropertyUIDelivery({ address, form, isDashboard }: Props) {
  const [propertyFields, setPropertyFields] = useState<ReactNode[]>([]);

  const switchPropertyFields = () => {
    switch (form.getValues("propertyType")) {
      case "APARTMENT": {
        setPropertyFields([
          <PropertySizeDelivery form={form} key="apartment_property_size" />,
          <ApartmentFloorDelivery form={form} key="apartment_floor_number" />,
          <AppartmentAccessDelivery form={form} key="apartment_access" />,
        ]);
        break;
      }

      case "HOUSE": {
        setPropertyFields([
          <PropertySizeDelivery form={form} key="house_property_size" />,
          <NumberStoriesDelivery form={form} key="house_number_stories" />,
          <HouseAccessDelivery form={form} key="house_access" />,
        ]);
        break;
      }

      case "STORAGE": {
        setPropertyFields([
          <UnitSizeDelivery form={form} key="storage_unitSize" />,
          <StorageFloorDelivery form={form} key="storage_floor_number" />,
          <StorageAccessDelivery form={form} key="storage_access" />,
        ]);
        break;
      }

      case "COMMERCIAL": {
        setPropertyFields([
          <SquareFootageDelivery form={form} key="commercial_square_footage" />,
          <ApartmentFloorDelivery form={form} key="commercial_floor_number" />,
          <CommercialAccessDelivery form={form} key="commercial_access" />,
        ]);
        break;
      }

      default: {
        setPropertyFields([
          <PropertySizeDelivery form={form} key="apartment_property_size" />,
          <ApartmentFloorDelivery form={form} key="apartment_floor_number" />,
          <AppartmentAccessDelivery form={form} key="apartment_access" />,
        ]);
        break;
      }
    }
  };

  useEffect(() => {
    switchPropertyFields();
  }, []);

  useEffect(() => {
    if (address) {
      switchPropertyFields();
    }
  }, [address]);

  return (
      <div className={`grid grid-cols-2  gap-y-4 gap-x-3`}>
          <section className={'flex flex-col gap-2'}>
              <p className={`text-[14px] leading-[16px] font-medium ${
                  form.getFieldState("propertyType").invalid
                      ? "text-[#C6241D] outline-[#C6241D] outline-2"
                      : ""
              }`}>Property type</p>
              <FormField
                  control={form.control}
                  name="propertyType"
                  render={({field}) => (
                      <FormItem className="flex-1">
                          <Select
                              onValueChange={(e) => {
                                  form.setValue("numberStories", "");
                                  form.setValue("propertySize", "");
                                  form.setValue("squareFootage", "");
                                  form.setValue("unitSize", "");
                                  form.setValue("floorNumber", "");
                                  form.setValue("access", "");
                                  form.setValue("propertyType", e);

                                  form.clearErrors("propertyType");
                                  form.clearErrors("numberStories");
                                  form.clearErrors("propertySize");
                                  form.clearErrors("squareFootage");
                                  form.clearErrors("unitSize");
                                  form.clearErrors("floorNumber");
                                  form.clearErrors("access");
                                  switchPropertyFields();
                              }}
                              value={field.value}
                          >
                              <FormControl>
                                  <SelectTrigger
                                      className={`${
                                          form.getFieldState("propertyType").invalid
                                              ? "text-[#C6241D] border-[#C6241D] border-2"
                                              : ""
                                      }`}
                                  >
                                      <div
                                          className={`${
                                              form.getValues("propertyType") === ""
                                                  ? "text-[#717171] pl-1"
                                                  : "text-marco_default_foreground"
                                          }
                  ${form.getFieldState("propertyType").invalid ? "!text-[#C6241D]" : ""}
                  text-[16px] leading-[24px] font-normal`}
                                      >
                                          <SelectValue placeholder="Property type"/>
                                      </div>
                                  </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                  <SelectItem key={"HOUSE"} value={"HOUSE"}>
                                      House
                                  </SelectItem>
                                  <SelectItem key={"APARTMENT"} value={"APARTMENT"}>
                                      Apartment
                                  </SelectItem>
                                  <SelectItem key={"STORAGE"} value={"STORAGE"}>
                                      Storage
                                  </SelectItem>
                                  <SelectItem key={"COMMERCIAL"} value={"COMMERCIAL"}>
                                      Other
                                  </SelectItem>
                              </SelectContent>
                          </Select>
                      </FormItem>
                  )}
              />
          </section>

          {propertyFields}
      </div>
  );
}
