import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import dayjs from "dayjs";

export async function postDeliveryDate(orderId: string, deliveryDate: Date, deliveryTime: 'MORNING' | 'AFTERNOON' | 'EVENING') {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else try {
        const currentDate = dayjs(deliveryDate).format("YYYY-MM-DD");

        const res = await fetch(ApiHost + encodeURI(`api/v1/order/deliveryDate`), {
            method: 'POST',
            body: JSON.stringify({
                orderId: orderId,
                deliveryDate: currentDate,
                deliveryTime: deliveryTime,
                deliverySpecificDate: false
            }),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: OrderDetails = await res.json();
            return data
        } else return await getDefinedError(res)

    } catch (e) {
        return otherError
    }
}