import type {UseFormReturn} from "react-hook-form";
import React from "react";
import {FormControl, FormField, FormItem} from "src/components/form";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";

interface Props {
    form: UseFormReturn<{
        access: string;
        floorNumber: string;
        numberStories: string;
        propertySize: string;
        propertyType: string;
        squareFootage: string;
        unitNumber: string;
        unitSize: string;
    }>;
}

export default function SquareFootageDelivery({form}: Props) {
    return (
        <section className={'flex flex-col gap-2'}>

            <p className={`text-[14px] leading-[16px] font-medium ${
                form.getFieldState("squareFootage").invalid
                    ? "text-[#C6241D] outline-[#C6241D] outline-2"
                    : ""
            }`}>Square footage</p>

            <FormField
                control={form.control}
                name="squareFootage"
                render={({field}) => (
                    <FormItem className="flex-1 relative">
                        <Select
                            onValueChange={(e) => {
                                form.setValue("squareFootage", e);
                                form.clearErrors("squareFootage");
                            }}
                            value={field.value}
                        >
                            <FormControl>
                                <SelectTrigger
                                    className={`${
                                        form.getFieldState("squareFootage").invalid
                                            ? "text-[#C6241D] outline-[#C6241D] outline-2"
                                            : ""
                                    }`}
                                >
                                    <div
                                        className={`${
                                            form.getValues("squareFootage") === ""
                                                ? "text-[#717171] pl-1"
                                                : "pl-1 text-marco_default_foreground"
                                        }
                  ${form.getFieldState("squareFootage").invalid ? "!text-[#C6241D]" : ""}
                  text-[16px] leading-[24px] font-normal`}
                                    >
                                        <SelectValue placeholder="Square footage"/>
                                    </div>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                <SelectItem key={"SQ_FT_500_1000"} value={"SQ_FT_500_1000"}>
                                    500-1000
                                </SelectItem>

                                <SelectItem key={"SQ_FT_1000_2000"} value={"SQ_FT_1000_2000"}>
                                    1000-2000
                                </SelectItem>

                                <SelectItem key={"SQ_FT_2000_3000"} value={"SQ_FT_2000_3000"}>
                                    2000-3000
                                </SelectItem>

                                <SelectItem key={"SQ_FT_3000_PLUS"} value={"SQ_FT_3000_PLUS"}>
                                    3000+
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    </FormItem>
                )}
            />
        </section>

    );
}
