import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {getCompany} from "src/utils/apiCalls/getCompany";
import {checkToken} from "src/utils/checkToken";
import routes from "src/utils/defaults/routes";
import {AccountStatus} from "src/utils/enums/accountStatus";
import {Role} from "src/utils/enums/role";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useViewport from "src/utils/zustandStores/primary/useViewport";

export default function Main() {
    const navigate = useNavigate();
    const {setDimensions} = useViewport();
    const {token, user, resetToken} = useTokenStore();

    async function checkVerification() {
        const isValid = await checkToken()
        if (isValid && user) {
            if (token) {
                switch (user.roles) {
                    case Role.driver: {
                        navigate(`${routes.loads}/booked`)
                        break
                    }

                    case Role.manager:
                    case Role.owner: {
                        const company = await getCompany();
                        if (company.status === AccountStatus.active) navigate(`${routes.search}`)
                        else navigate(routes.companyPaymentsAndPayouts)
                        break
                    }
                }
            } else {
                resetToken();
                navigate(routes.login)
            }
        } else {
            resetToken();
            navigate(routes.login)
        }
    }

    useEffect(() => {
        checkVerification()
    }, []);

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
        return () => window.removeEventListener("resize", setDimensions);
    }, []);

    return <div className={'h-dvh'}></div>
}